import React from 'react'
import logo from '../../assets/images/PP_logoBlack.svg';
import './preloader.scss'
const Preloader = () => {
    return (
        <div className="fixed inset-0 bg-white flex justify-center items-center">
            {/* Spinner (Tailwind CSS) */}
            <div className='relative'>
                <div className="preloaderIcon">
           <img src={logo} alt="Pixel Pace Technologies" />
                </div>
            <div className="animate-spin rounded-full h-40 w-40 border-b-2 border-gray-900">
            </div>
            </div>
           
        </div>
    );
}

export default Preloader
