import React, { useRef } from 'react'
import './home.scss'
import Button from '../../../components/button/Button'
import videoBanner from '../../../assets/media/video/bannerVideo.mp4'
import { useInView } from 'react-intersection-observer';

const heroContentArr = [
  {
    subTitle: "WELCOME TO PIXEL PACE TECHNOLOGIES",
    title: "Transforming Ideas into Digital Success: Expert <span style='color: #0FE3E3'>Website Design </span> & <span style='color: #0FE3E3'> Digital Marketing </span> Services",
    detail: "At Pixel Pace Technologies, we blend creativity with technology to deliver expert website design and digital marketing strategies. Discover how our innovative solutions can elevate your brand online. Contact us today!",

  },
]

const HomeBanner = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const videoRef = useRef(null);

  function scrollToSection(target) {
    const section = document.getElementById(target);

    if (section) {
      let offsetY = 0; // Default offset

      if (window.innerWidth < 1950) {
        offsetY = 160; // Adjust offset for smaller screens
      }
      if (window.innerWidth < 1690) {
        offsetY = 110; // Adjust offset for smaller screens
      }
      if (window.innerWidth < 1390) {
        offsetY = 90; // Adjust offset for smaller screens
      }
      if (window.innerWidth < 568) {
        offsetY = 75; // Adjust offset for smaller screens
      }
      const sectionPosition = section.getBoundingClientRect().top + window.scrollY - offsetY;
      window.scrollTo({
        top: sectionPosition,
        behavior: 'smooth',
      });
    }
  }


  return (
    <section className='homeBg'>
      <div className='videoSec'>
        <video ref={videoRef} muted loop autoPlay playsInline >
          <source src={videoBanner} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="relative container flex xl:justify-between justify-center xl:items-center h-full xl:flex-row flex-col xl:gap-0 md:gap-[50px] gap-[24px]">
        <div ref={ref} className='xl:w-[70%] md:w-[85%] w-full'>
          {heroContentArr?.map((heroBannerData, index) => (
            <div key={index}>
           <h6 className={`text-white fs20 font-semibold ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>{heroBannerData.subTitle}</h6>
           <h1 dangerouslySetInnerHTML={{ __html: heroBannerData.title }} className={`text-white md:my-4 my-2 font-bold fs45 leading-normal ${inView ? ' animate__animated animate__fadeInUp' : ''}`}></h1>
           <p className={`fs20 text-white min-[1370px]:mb-8 mb-4 leading-normal  ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                {heroBannerData.detail}
              </p>
            </div>
          ))}
          <div className={`flex xl:gap-6 gap-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
            <Button btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" shadowClr='#000' btnTxt="Start a Project" className="startProjectBtn w-fit py-5 px-7 leading-tight max-[1500px]:py-4 max-[1500px]:px-5 bg-[#04B0B0] border-[1px] border-[#04B0B0] text-white font-semibold hover:border-[1px] hover:border-white fs18 hover:text-white max-[1370px]:py-3 max-[1370px]:px-4" />
            <div onClick={() => scrollToSection('aboutUsSec')}>
              <Button btnClick='aboutUs' shadowClr='#000' btnTxt="About Us" className="aboutBtn w-fit py-5 px-10 bg-transparent leading-tight border-[1px] max-[1500px]:py-4 max-[1500px]:px-8 max-[1370px]:py-3 max-[1370px]:px-6 text-white border-white font-semibold hover:border-[0px] fs18 hover:text-white" />
            </div>
          </div>
        </div>
      </div>
    </section >
  )
}

export default HomeBanner



// form code start
  {/* <div className='max-w-[406px] w-full max-[1370px]:max-w-[380px]'>
      <FormBox fsLow='text-[10px]' fsHigh='text-[12px]' />
    </div> */}
    // form code end