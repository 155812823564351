import React from 'react'
import Button from '../../components/button/Button'
import QuoteCallIcon from '../../assets/images/QuoteCallIcon'
import ScrollAnimation from 'react-animate-on-scroll';
import bgImg from '../../assets/images/request_back.png';
const Quote = () => {
    const bgStyle = {
        background: 'linear-gradient(90deg, #003D3D 15.52%, #006363 82.82%)',
    }
    
  return (
    <div className='text-center py-14 max-[1680px]:py-12 max-[575px]:py-8 bg-cover' style={{ backgroundImage: `url(${bgImg})` }}>
        <div className="container">
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
          <h2 className='fs40 font-bold text-white interFont'>Submit your project and get free quotes in 1-hour</h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
          <p className='fs30 font-medium leading-normal my-6 text-white interFont max-[1680px]:mt-4 max-[575px]:my-3'>Let’s Discuss on Call</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
          <Button btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt="Call Us" btnIcon={<QuoteCallIcon className='w-[36px] max-[1500px]:w-[30px] max-[1370px]:w-[28px]'/>} className='bg-[#DFFFFF] text-[#003D3D] py-4 px-6 max-[1500px]:py-3 max-[1500px]:px-4 max-[1370px]:py-2 fs22 font-semibold gap-4 max-[1370px]:gap-2 w-fit mx-auto rounded-[20px] max-[1500px]:gap-3 before:bg-black hover:text-white' shadowClr='rgba(0,0,0,0.5)' />
          </ScrollAnimation>
        </div>
    </div>
  )
}

export default Quote
