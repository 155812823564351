import React, { useState } from 'react';
import InfoIcon from '../../assets/images/InfoIcon';
import FeeIcon from '../../assets/images/fee';
import Mountain from '../../assets/images/mountain';
import Cost from '../../assets/images/cost';
import ScrollAnimation from 'react-animate-on-scroll';
// const counterArr = [
//   {
//     id: 1,
//     title: '7x',
//     detail: 'Faster Than Hiring In-House',
//     subTitle: 'Average time to hire for developer roles is around 30 days - DHI Group Report',
//     animate: 'fade-right',
//   },
//   {
//     id: 2,
//     title: '66%',
//     detail: 'Less Employment Cost per Month',
//     subTitle: 'Average time to hire for developer roles is around 30 days - DHI Group Report',
//     animate: 'fade-down',
//   },
//   {
//     id: 3,
//     title: '9.4',
//     detail: 'Average Customer Rating',
//     subTitle: 'Average time to hire for developer roles is around 30 days - DHI Group Report',
//     animate: 'fade-left',
//   },
// ];

const dataArr = [
  {
    img: <FeeIcon className="w-[40px] mx-auto" iconClr="#CAFFFF"/>,
    title1: "No Hidden Fees",
    title2: "What you see is what you pay. Our pricing model is straightforward, with no hidden costs or surprise fees.",
    animate: "fadeInUp",
  },
  {
    img: <Mountain className="w-[40px] mx-auto" iconClr="#CAFFFF"/>,
    title1: "7x Faster Than Hiring In-House",
    title2: "Reduce overheads and ramp up your team's output with our expert remote workforce.",
    animate: "fadeInUp",
  },
  {
    img: <Cost className="w-[40px] mx-auto" iconClr="#CAFFFF"/>,
    title1: "66% Reduction in Employment Costs",
    title2: "Maximize your budget with our cost-effective solutions compared to traditional employment.",
    animate: "fadeInDown",
  },
  {
    img: <FeeIcon className="w-[40px] mx-auto" iconClr="#CAFFFF"/>,
    title1: "High Satisfaction Guarantee",
    title2: "With an average customer rating of 9.4, our services are backed by a 100% satisfaction guarantee.",
    animate: "fadeInUp",
  },
]

const Counter = () => {
  // const [isInfoHover, setIsInfoHover] = useState(Array(counterArr.length).fill(false));

  // const boxShadow = {
  //   boxShadow: '0 4px 31px rgba(0, 0, 0, 0.07)',
  // };

  // const handleInfoIconHover = (elementId) => {
  //   const updatedHoverState = isInfoHover.map((value, index) => index === elementId - 1);
  //   setIsInfoHover(updatedHoverState);
  // };

  return (
    <div className='py100'>
      <div className="container">
        <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
        <h2 className='text-center fs40'>
          Benefits of Our Pricing Model
        </h2>
        </ScrollAnimation>
        
        <div className='flex justify-between flex-wrap pt80 gap-y-4'>
          {
            dataArr.map((data, index) => (
              <ScrollAnimation animateIn={data.animate} key={index} className='counterBox text-center bg-[#006363] px-5 py-6 rounded-[8px] text-white'>
                {data.img}
                <h6 className='mr-1 font-semibold fs22 my-2 min-h-[38.38px] flex flex-col justify-center'>{data.title1}</h6>
                <p className='min-[1680px]:text-[16px] text-[15px] leading-snug'>{data.title2}</p>
              </ScrollAnimation>
            ))
          }
        </div>


        {/* <ul className='flex justify-between gap-3 max-w-[930px] w-full mx-auto max-[1370px]:max-w-[750px] max-[991px]:justify-center max-[991px]:gap-12 max-[575px]:flex-col max-[575px]:justify-center max-[575px]:items-center'>
          {counterArr.map((counterD, index) => (
            <li data-aos={`${counterD.animate}`} key={counterD.id} className='relative text-center max-w-[206px] w-full max-[1370px]:max-w-[180px] max-[991px]:max-w-[150px]'>
              <h2  className='text-[#003D3D] font-bold fs50'>{counterD.title}</h2>
              <p  className='text-[#212529] font-semibold fs22 leading-tight'>{counterD.detail}</p>
              <div
                 className='absolute w-5 h-5 cursor-pointer right-[-20px] bottom-[5px]'
                onMouseEnter={() => handleInfoIconHover(counterD.id)}
                onMouseLeave={() => handleInfoIconHover(null)}
              >
                <InfoIcon className='w-full' iconClr='#0FE3E3' />
                <p
                  className={`bg-black py-5 px-4 min-w-[250px] fs16 min-h-[113px] rounded-md transition-all text-white font-medium absolute bottom-full top-auto -translate-x-1/2 mb-1 max-[1370px]:py-3 max-[1370px]:px-2 max-[1370px]:min-w-[200px] z-40 ${isInfoHover[index] ? 'opacity-100 visible' : 'opacity-0 invisible'}`}
                  style={boxShadow}
                >
                  {counterD.subTitle}
                </p>
              </div>
            </li>
          ))}
        </ul> */}
      </div>
    </div>
  );
};

export default Counter;
