import React, { useEffect } from 'react'
import './workProcess.scss'
import Button from '../../../components/button/Button'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import ScrollAnimation from 'react-animate-on-scroll';
const processArr = [
    {
        imgSrc: require('../../../assets/images/procee1.png'),
        alt: "Discovery & Planning at Pixel Pace Technologies",
        subTitle: "Phase 1",
        title: "Discovery & Planning",
        detail: <>
            Start your journey with Pixel Pace Technologies where our expert team dives deep into understanding your project's objectives and challenges. We meticulously plan to align our strategies with your business goals, ensuring a tailored approach from the outset.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee2.png'),
        alt: "Design & Development at Pixel Pace Technologies",
        subTitle: "Phase 2",
        title: "Design & Development",
        detail: <>
            Our core strength in Website Design and Development comes to the forefront as we transform concepts into user-centric designs and robust digital solutions. Each project is crafted with precision, blending aesthetics with functionality to create outstanding websites and applications.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee3.png'),
        alt: "Iterate & Test at Pixel Pace Technologies",
        subTitle: "Phase 3",
        title: "Iterate & Test",
        detail: <>
            Rigorous testing and iteration ensure that every digital product we develop meets our high standards of quality and effectiveness. This phase is critical for refining our designs and functionalities, ensuring they meet your expectations and deliver a superior user experience.
        </>
    },
    {
        imgSrc: require('../../../assets/images/procee4.png'),
        alt: "Integrate & Support at Pixel Pace Technologies",
        subTitle: "Phase 4",
        title: "Integrate & Support",
        detail: <>
            Post-launch, our integration services ensure that your new website or application seamlessly melds with existing platforms, supported by our continuous expert guidance and technical support. We ensure smooth operation and scalability, preparing you for growth and success.

        </>
    },
    {
        imgSrc: require('../../../assets/images/procee5.png'),
        alt: "Digital Marketing at Pixel Pace Technologies",
        subTitle: "Phase 5",
        title: "Digital Marketing",
        detail: <>
            Leverage our expertise in Digital Marketing to amplify your online presence. We employ strategic marketing campaigns designed to attract and engage your target audience, increase traffic, and enhance your brand visibility online.

        </>
    },
]
const WorkProcess = () => {
    // const { pathname } = useLocation();
    const backtoTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div className='workProcess py100'>
            <div className="container">
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <h2 className='fs45 text-white font-bold text-center pb80'>Our Work Process</h2>
                </ScrollAnimation>
                
                <div className='xl:p-0 px-2'>
                    {
                        processArr.map((processData, index) => (
                            <div key={index} className='workRow flex justify-between items-center lg:flex-row flex-col-reverse gap-x-6 lg:gap-y-0 gap-y-4'>
                                <ScrollAnimation animateIn='fadeInLeft' animateOnce={true}  className='lg:w-1/2 min-[1690px]:h-[420px] min-[1370px]:h-[320px] h-[260px]'  >
                                    <img className='w-full h-full object-contain' src={processData.imgSrc} alt={processData.alt}  />
                                </ScrollAnimation>
                                <ScrollAnimation animateIn='fadeInRight' animateOnce={true}  className='lg:w-1/2'>
                                    <span   className='block text-[#0FE3E3] fs30 font-semibold'>{processData.subTitle}</span>
                                    <h3   className='text-white fs40 font-medium titleMain'>{processData.title}</h3>
                                    <p   className='fs20 font-medium'>{processData.detail}</p>
                                </ScrollAnimation>
                            </div>
                        ))
                    }
                </div>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <h3 className='text-white fs28 font-medium'>Engage with Pixel Pace Today</h3>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp' animateOnce={true} >
                <p className='fs20 font-medium mt-2 mb-4'>
                    Ready to elevate your online presence? Click below to initiate your project with Pixel Pace Technologies and harness our expert services in Website Design and Digital Marketing to achieve unparalleled success.
                </p>
                </ScrollAnimation>
                
                <ScrollAnimation animateIn='fadeInDown' animateOnce={true} >
                <Link onClick={backtoTop} to='/'>
                    <Button btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt="Start a Project" className='w-fit bg-[#DFFFFF] fs20 text-[#003D3D] font-semibold mx-auto py-5 px-8 max-[1500px]:px-6 max-[1500px]:py-4 max-[1370px]:px-4 max-[1370px]:py-3 before:bg-black hover:text-white' shadowClr='rgba(0,0,0,0.5)' />
                </Link>
                </ScrollAnimation>
                
            </div>
        </div>
    )
}

export default WorkProcess
