import React from 'react'

const QuoteCallIcon = ({className}) => {
  return (
<svg className={`${className}`} width="100%" height="100%" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="23" cy="23" r="23" fill="#003D3D" fillOpacity="0.5"/>
<circle cx="23" cy="23.0001" r="17.026" fill="#336464"/>
<path d="M30.5825 31.6626C28.6619 31.6626 26.7324 31.2161 24.7942 30.323C22.8559 29.43 21.0735 28.1702 19.4469 26.5435C17.8268 24.9169 16.5702 23.1361 15.6772 21.2011C14.7842 19.2661 14.3376 17.3384 14.3376 15.4178C14.3376 15.112 14.4396 14.8556 14.6434 14.6485C14.8472 14.4414 15.102 14.3379 15.4077 14.3379H18.7315C18.9889 14.3379 19.2159 14.4218 19.4125 14.5897C19.6092 14.7576 19.7343 14.965 19.7878 15.212L20.3718 18.2105C20.4124 18.4888 20.4039 18.7278 20.3464 18.9277C20.2889 19.1276 20.1857 19.2955 20.0367 19.4314L17.683 21.7224C18.0619 22.4162 18.4947 23.0724 18.9814 23.6911C19.4681 24.3097 19.9949 24.9006 20.562 25.4637C21.1212 26.0229 21.7157 26.5423 22.3454 27.0218C22.9752 27.5013 23.6552 27.9475 24.3856 28.3603L26.6727 26.0536C26.8321 25.8877 27.0251 25.7714 27.2518 25.7048C27.4785 25.6382 27.714 25.6218 27.9583 25.6558L30.7882 26.232C31.0456 26.2999 31.2557 26.4312 31.4183 26.6259C31.581 26.8206 31.6623 27.0414 31.6623 27.2883V30.5925C31.6623 30.8982 31.5588 31.153 31.3517 31.3568C31.1446 31.5607 30.8882 31.6626 30.5825 31.6626Z" fill="white"/>
</svg>

  )
}

export default QuoteCallIcon
