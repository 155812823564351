import React from 'react'

const PricingArrow = ({ className, iconClr }) => {
    
    return (
        <svg className={`${className}`} width="74" height="35" viewBox="0 0 74 35" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M1.91446 3.43074C20.402 15.4016 44.1751 23.0185 66.5056 22.1239" stroke={iconClr}
                strokeWidth="3" strokeLinecap="round" />
            <path d="M56.6604 11.8778C61.2115 18.0512 66.9825 22.1046 72.3194 21.8908" stroke={iconClr}
                strokeWidth="3" strokeLinecap="round" />
            <path d="M57.5115 33.1244C61.5542 26.6067 66.9825 22.1046 72.3194 21.8908" stroke={iconClr}
                strokeWidth="3" strokeLinecap="round" />
        </svg>
    )
}

export default PricingArrow
