import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/webDevBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';
const dataArr = [
  {
    subtitle:"Customized Solutions:",
    title:"We design and develop websites that are not only visually appealing but also highly functional, ensuring they meet the specific needs of your business. ",
  },
  {
    subtitle:"Aesthetic and Functional Design:",
    title:"With a focus on both aesthetics and usability, our websites offer a seamless user experience that engages and retains visitors.",
  },
  {
    subtitle:"Result-Driven Approaches: ",
    title:"Our web development projects are crafted to drive tangible results, enhancing your online presence and contributing to your business growth.",
  },
]
const WebDevSer = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});

  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container h-full flex flex-col">
        <BrowserTabTitle tabTitle={'Expert Web Development Services | Pixel Pace Technologies'} metaDescription={`Unlock your digital potential with Pixel Pace Technologies. Specializing in creating dynamic, functional websites tailored to your business goals. Elevate your online presence with our cutting-edge web development solutions that blend aesthetic design with practical functionality. Contact us today to transform your digital dreams into reality.`} />
        <ServicePage title="Web Development Services at Pixel Pace Technologies" subTitle='Turn Your Digital Dreams into Reality' detail='At Pixel Pace Technologies, we excel in transforming your digital dreams into practical, functional realities. Our web development services are tailored to create dynamic and innovative online solutions that perfectly align with your business objectives.' />
        <div className='pt100' ref={ref}>
            <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Us for Web Development?</h3>
          {
            dataArr.map((data,index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
              <p className='fs22 leading-normal'>
                <b className='mr-1'>
                  {data.subtitle} 
                </b>
                {data.title}
              </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default WebDevSer
