import React from 'react'

const TikTokIcon = ({iconClr}) => {
  return (
   <a href="https://www.tiktok.com/@pixelpacetechnologies" target='_blank' rel="noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z" fill={iconClr}/></svg>
   </a>
  )
}

export default TikTokIcon
