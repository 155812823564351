import React from 'react'

const ChartIcon = ({iconClr, className}) => {
  return (
<svg className={`${className}`} width="100%" height="100%" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.41469 15.2957V19.7957M15.955 13.0457V19.7957M11.1849 8.54565V19.7957M20.7252 7.19565V18.8957C20.7252 20.7858 20.7252 21.7309 20.3352 22.4529C19.9922 23.0879 19.4449 23.6042 18.7718 23.9278C18.0065 24.2957 17.0046 24.2957 15.001 24.2957H7.36872C5.36507 24.2957 4.36324 24.2957 3.59795 23.9278C2.92477 23.6042 2.37747 23.0879 2.03447 22.4529C1.64453 21.7309 1.64453 20.7858 1.64453 18.8957V7.19565C1.64453 5.30548 1.64453 4.36039 2.03447 3.63844C2.37747 3.00339 2.92477 2.48708 3.59795 2.16351C4.36324 1.79565 5.36507 1.79565 7.36872 1.79565H15.001C17.0046 1.79565 18.0065 1.79565 18.7718 2.16351C19.4449 2.48708 19.9922 3.00339 20.3352 3.63844C20.7252 4.36039 20.7252 5.30548 20.7252 7.19565Z" stroke={iconClr} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default ChartIcon
