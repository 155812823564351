import React from 'react'

const ClockIcon = (props) => {
    return (
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.29001 0.25C12.5713 0.25 16.04 3.71875 16.04 8C16.04 12.2812 12.5713 15.75 8.29001 15.75C4.00876 15.75 0.540009 12.2812 0.540009 8C0.540009 3.71875 4.00876 0.25 8.29001 0.25ZM11.165 10.0312C11.2275 9.96875 11.29 9.84375 11.29 9.71875C11.29 9.5625 11.1963 9.4375 11.1025 9.34375L9.29001 8V3.5C9.29001 3.25 9.04001 3 8.79001 3H7.79001C7.50876 3 7.29001 3.25 7.29001 3.5V8.375C7.29001 8.78125 7.44626 9.125 7.75876 9.34375L9.85251 10.9062C9.91501 10.9688 10.04 11.0312 10.1338 11.0312C10.3213 11.0312 10.4463 10.9375 10.54 10.8125L11.165 10.0312Z" fill={props.iconClr} />
            </svg>
    )
}

export default ClockIcon
