import React from 'react'
import Button from '../../components/button/Button'
import servicePhone from '../../assets/images/servicePhone.png'
import './service.scss'
import ScrollAnimation from 'react-animate-on-scroll';
import { useInView } from 'react-intersection-observer';

const ServicePage = ({ title, detail, subTitle }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });
    return (
        <>
            <div ref={ref} className="flex xl:justify-between items-center flex-wrap justify-center xl:gap-4 gap-8 lg:flex-row flex-col content-center">
                <div className='xl:w-[50%] w-full max-[575px]:text-center'>
                    <h1 className={`fs45 text-[#1D2C38] font-extrabold ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>{title} <span className='font-semibold block fs22 lg:mb-4 mb-2 mt-2'>{subTitle}</span></h1>
                    <p className={`text-[#302F2F] fs24 min-[1690px]:pb-[45px] leading-relaxed md:pb-[25px] pb-[10px] ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>{detail}</p>
                    <Button shadowClr="rgba(0,0,0,0.15)" btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" btnTxt='Book Appointment' className={`bg-[#04B0B0] w-fit px-6 py-4 max-[1370px]:px-4 max-[1370px]:py-3 text-white fs22 font-semibold max-[575px]:m-[auto] before:bg-black ${inView ? 'animate__animated animate__fadeInUp' : ''}`} />
                </div>
                <div className={`bg-[#006363] rounded-[10px] xl:w-[40%] w-full px-[55px] py-[50px] text-white text-center max-[1440px]:p-[40px] max-[1370px]:p-[35px] ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                    <div className='max-w-[125px] w-full mx-auto max-[1440px]:max-w-[100px] max-[1370px]:max-w-[80px] max-[575px]:max-w-[60px]'>
                        <a href="tel:+61 417 205 778"><img src={servicePhone} alt="Contact us | Pixel Pace Technologies" /></a>
                    </div>
                    <h3 className='fs30 leading-snug font-extrabold mt-12 mb-8 max-[1680px]:mt-6 max-[1680px]:mb-4 max-[575px]:my-2'>Have any Question? <br />
                        Call Us Now!</h3>
                    <a className='fs24 font-medium' href="tel:+61 417 205 778">+61 417 205 778</a>
                </div>
            </div>
        </>
    )
}

export default ServicePage
