import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/marketBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle: "Strategic Marketing Planning:",
    title: "We begin by understanding your business goals and crafting a tailored marketing strategy that encompasses the latest techniques and data-driven insights.",
  },
  {
    subtitle: "SEO and Content Marketing:",
    title: "Increase your search engine rankings and draw high-quality traffic with our expert SEO services and compelling content marketing strategies.",
  },
  {
    subtitle: "Social Media Management:",
    title: "Engage with your audience where they are most active. Our team manages your social media profiles, creating content that resonates and drives interactions.",
  },
  {
    subtitle: "Email Marketing and Automation:",
    title: "Leverage our targeted email marketing strategies and automation services to nurture leads and convert them into loyal customers.",
  },
]
const Marketing = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Comprehensive Digital Marketing Solutions | Pixel Pace Technologies'} metaDescription={`Boost your brand’s online presence with Pixel Pace Technologies. Our digital marketing solutions are crafted to increase visibility, engage audiences, and drive growth. From SEO to social media marketing, discover innovative strategies that align with your goals. Book a free consultation today!`} />
        <ServicePage title="Elevate Your Brand with Our Marketing Solutions" subTitle='Comprehensive Digital Marketing Services' detail="At Pixel Pace Technologies, we are dedicated to empowering your brand to excel in the digital landscape. We offer a suite of comprehensive marketing solutions designed to enhance your online visibility, engage your target audience effectively, and foster sustainable business growth." />
        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>What We Offer:</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Marketing
