import React from 'react'
import PricingBanner from './pricingBanner/PricingBanner'
import './pricing.scss'
import Counter from './Counter'
import BusinessGrow from './BusinessGrow'
import DiscoveryCall from './DiscoveryCall'
import ContactUs from '../../pages/contactUS/ContactUs'
import BrowserTabTitle from '../../components/browserTabTitle'
const PricingPage = () => {
  return (
    <>
      <BrowserTabTitle tabTitle="Transparent & Predictable Pricing for Web Design and Digital Marketing | Pixel Pace Technologies" metaDescription="Unlock predictable pricing with no surprises at Pixel Pace Technologies. Explore our scalable plans for web design and digital marketing services at predictable price, tailored to fast-moving agencies and marketing teams. Book a free consultation for a custom solution."/>
      <div>
        <PricingBanner/>
        <Counter/>
        <BusinessGrow/>
        <DiscoveryCall/>
        <ContactUs/>
      </div>
    </>
  )
}

export default PricingPage
