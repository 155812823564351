import React, { useMemo, useState } from 'react';
import Chatbot from 'react-simple-chatbot';
import './index.scss';
import CommentIcon from '../../assets/images/CommentIcon';
import CrossIcon from '../../assets/images/CrossIcon';
import { ThemeProvider } from 'styled-components';
import CustomBotAvatar from '../../assets/images/chatbotIcon.png';

// all available props
const theme = {
    background: '#f5f8fb',
    headerBgColor: '#006363',
    headerFontColor: '#fff',
    headerFontSize: '15px',
    botBubbleColor: '#CAFFFF',
    botFontColor: '#000',
    userBubbleColor: '#006363',
    userFontColor: '#fff',
};

const ChatbotBox = () => {
    const [showChatbot, setShowChatbot] = useState(false);
    
    // Memoizing the steps to avoid re-creating them on every render
    const steps = useMemo(() => [
      {
        id: 'message',
        message: 'Welcome to Pixel Pace Technologies! How can we assist you today?',
        trigger: 'AskService',
      },
      {
        id: 'AskService',
        message: 'Which of our services are you interested in?',
        trigger: 'Services',
      },
      {
        id: 'Services',
        options: [
          { value: 'Web Development', label: 'Web Development', trigger: 'response' },
          { value: 'Web Design', label: 'Web Design', trigger: 'response' },
          { value: 'Digital Marketing', label: 'Digital Marketing', trigger: 'response' },
          { value: 'UI/UX Design', label: 'UI/UX Design', trigger: 'response' },
          { value: 'WordPress', label: 'WordPress Solutions', trigger: 'response' },
          { value: 'Shopify', label: 'Shopify Development', trigger: 'response' },
          { value: 'Mobile Application', label: 'Mobile Application Development', trigger: 'response' },
        ],
      },
      {
        id: 'response',
        message: 'Thank you for your interest in {previousValue}.',
        trigger: 'AskName',
      },
      {
        id: 'AskName',
        message: 'Can I have your name, please?',
        trigger: 'waiting1',
      },
      {
        id: 'waiting1',
        user: true,
        trigger: 'askEmail',
      },
      {
        id: 'askEmail',
        message: 'Thank you, {previousValue}. Could you please provide your email so we can follow up?',
        trigger: 'waiting2',
      },
      {
        id: 'waiting2',
        user: true,
        trigger: 'askMobile',
      },
      {
        id: 'askMobile',
        message: 'May I have your mobile number for further communication?',
        trigger: 'waiting3',
      },
      {
        id: 'waiting3',
        user: true,
        trigger: 'thanksMessage',
      },
      {
        id: 'thanksMessage',
        message: 'Thank you for sharing your details!',
        trigger: 'finalMessage',
      },
      {
        id: 'finalMessage',
        message: 'We appreciate your interest in Pixel Pace Technologies. We will be in touch soon!',
        end: true,
      },
    ], []);
    

      // Function to toggle chatbot visibility
    const toggleChatbot = () => {
        setShowChatbot(!showChatbot);
    };

    return (
        <div className="chatBotWrap">
            {/* Conditionally render the comment icon if chatbot is not visible */}
            {!showChatbot && (
                <div className="commentIcon transition-all duration-700" onClick={toggleChatbot}>
                    {/* Message icon */}
                    <CommentIcon iconClr="#000" />
                    <div className="dots">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}

            {/* Chatbot container with sliding animation */}
            <div className={`chatBotContainer ${showChatbot ? 'open' : ''}`}>
                {/* Chatbot header with close icon */}
                <ThemeProvider theme={theme} className='relative'>
                    <div className="closeIcon" onClick={() => setShowChatbot(false)}>
                        <CrossIcon iconClr="#fff" />
                    </div>
                    <Chatbot
                        steps={steps}
                        botAvatar={CustomBotAvatar}
                    />
                </ThemeProvider>
            </div>
        </div>
    );
};

export default ChatbotBox;
