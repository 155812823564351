import React from 'react'

const LeftChevron = ({className,iconClr}) => {
  return (
    <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 19 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.83222 16.9583L17.6422 3.14834C18.0065 2.78403 18.184 2.35038 18.1746 1.8474C18.1652 1.3444 17.9783 0.910756 17.614 0.546472C17.2497 0.182159 16.816 0 16.3131 0C15.8101 0 15.3764 0.182159 15.0121 0.546472L0.97653 14.6102C0.645124 14.9417 0.399506 15.313 0.23967 15.7243C0.0798645 16.1357 -3.8147e-05 16.547 -3.8147e-05 16.9583C-3.8147e-05 17.3696 0.0798645 17.7809 0.23967 18.1923C0.399506 18.6036 0.645124 18.9749 0.97653 19.3063L15.0403 33.3701C15.4046 33.7344 15.8336 33.9119 16.3272 33.9025C16.8207 33.8931 17.2497 33.7062 17.614 33.3419C17.9783 32.9776 18.1605 32.544 18.1605 32.041C18.1605 31.538 17.9783 31.1044 17.614 30.7401L3.83222 16.9583Z" fill={iconClr} />
    </svg>
    
  )
}

export default LeftChevron
