import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import RangeSlider from "./RangeSlider";
import { Link, useNavigate } from "react-router-dom";
import bgImg from "../../../assets/images/request_back.png";
import axios from "axios";
import Thankfulness from "./Thankfulness";
import { useInView } from "react-intersection-observer";
import ScrollAnimation from "react-animate-on-scroll";
const inputArr = [
  {
    label: "Your name",
    name: "name",
    imp: "*",
    type: "text",
    placeholder: "Please enter name",
    validate: (value) => value.trim() !== "",
  },
  {
    label: "Email",
    name: "email",
    imp: "*",
    type: "email",
    placeholder: "Please enter email",
    validate: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
  },
  {
    label: "Location",
    name: "location",
    imp: "*",
    type: "text",
    placeholder: "City, Country",
    validate: (value) => value.trim() !== "",
  },
  {
    label: "Company",
    name: "company",
    imp: "*",
    type: "text",
    placeholder: "Company name",
    validate: (value) => value.trim() !== "",
  },
];

const serviceArr = [
  {
    name: "Web Development",
  },
  {
    name: "Web Design",
  },
  {
    name: "Digital marketing",
  },
  {
    name: "UI/UX",
  },
  {
    name: "Wordpress",
  },
  {
    name: "Shopify",
  },
  {
    name: "Mobile application",
  },
];

const GetInTouch = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    location: "",
    company: "",
    service: "",
    website: "",
    projectDetails: "",
    price: "400",
  });
  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [barHeight, setBarHeight] = useState(0);
  const contactRows = useRef([]);
  const [serviceIndex, setServiceIndex] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const navigate = useNavigate(); // Use React Router's useNavigate for programmatic navigation

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const totalHeight =
  //       document.documentElement.scrollHeight - window.innerHeight;
  //     const scrollTop = window.scrollY;
  //     const scrollPercent = (scrollTop / totalHeight) * 150;

  //     setBarHeight(scrollPercent);

  //     contactRows.current.forEach((section, index) => {
  //       const rect = section.getBoundingClientRect();
  //       if (
  //         rect.top <= window.innerHeight / 2 &&
  //         rect.bottom >= window.innerHeight / 2
  //       ) {
  //         setActiveIndex(index);
  //       }
  //     });
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const totalHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollTop = window.scrollY;
      const scrollPercent = (scrollTop / totalHeight) * 150;

      setBarHeight(scrollPercent);

      contactRows.current.forEach((section, index) => {
        // Check if the section exists before calling getBoundingClientRect
        if (section) {
          const rect = section.getBoundingClientRect();
          if (
            rect.top <= window.innerHeight / 2 &&
            rect.bottom >= window.innerHeight / 2
          ) {
            setActiveIndex(index);
          }
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const validateForm = () => {
    const newErrors = {};
    inputArr.forEach((field) => {
      if (!field.validate(formData[field.name])) {
        newErrors[
          field.name
        ] = `Please enter a valid ${field.label.toLowerCase()}.`;
      }
    });
    if (!formData.service) {
      newErrors.service = "Please select a service.";
    }
    if (!formData.projectDetails.trim()) {
      newErrors.projectDetails = "Project details are required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    // Check if the form is valid whenever formData changes
    setIsFormValid(validateForm());
  }, [formData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true); // Flag that form was submitted
    if (!validateForm()) return;

    try {
      const response = await axios.post(
        "https://pixelpacetechnologies.com/backendpixelpacetechnologies/submit",
        // "http://localhost:5000/submit",
        formData
      );
      console.log(response.data.message);
      setFormData({
        name: "",
        email: "",
        location: "",
        company: "",
        service: "",
        website: "",
        projectDetails: "",
        price: 0,
      });
      setErrors({});
      navigate("/thankfull-message");
    } catch (error) {
      console.error("Error submitting the form", error);
      setErrors({
        form: "An error occurred while submitting the form. Please try again.",
      });
    }
  };

  const serviceClick = (index) => {
    setServiceIndex(index);
    setFormData({ ...formData, service: serviceArr[index].name });
  };

  const handlePriceChange = (price) => {
    setFormData((prevState) => ({ ...prevState, price }));
    setSliderValue(price);
  };

  return (
    <section className={`py100 getTouch serviceBg before:bg-[${bgImg}]`}>
      <div className="max-w-[950px] w-full mx-auto xl:px-0 md:px-4 px-2">
        <div className="lg:ms-[100px]" ref={ref}>
          <h1
            className={`xl:text-[70px] md:text-5xl text-4xl md:mt-4 md:mb-10 mb-6 ${
              inView ? "animate__animated animate__fadeInUp" : ""
            }`}
          >
            Let's talk!
          </h1>
          <p
            className={`fs24 md:ps-6 ps-3 border-s-4 border-[#04B0B0] leading-relaxed ${
              inView ? "animate__animated animate__fadeInUp" : ""
            }`}
          >
            Get a free estimate. Tell us all about your project right here, or
            send us an email at <br />
            <a
              href="mailto:info@pixelpacetechnologies.com"
              className="text-[#04B0B0] ms-1"
            >
              info@pixelpacetechnologies.com
            </a>
          </p>
        </div>

        {/* <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          
        </ScrollAnimation> */}
        <form className="relative" onSubmit={handleSubmit}>
            <div className="h-full lg:flex hidden flex-col justify-center items-center w-[60px] absolute">
              <div className="min-[1370px]:w-[3px] w-[2px] h-[70%] relative overflow-hidden">
                <div className="w-full h-full bg-black absolute left-0 right-0 top-0 bottom-0 bar">
                  <div
                    className="activeBar bg-[#04b0b0]"
                    style={{
                      height: `${barHeight}%`, // The bar fills based on scroll percentage
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className={`contactRow ${
              inView ? "animate__animated animate__fadeInUp" : ""
            }`}
              ref={(el) => (contactRows.current[0] = el)}
              data-index="0"
            >
              <div className={`stepIndicator`}>
                <h1>1</h1>
              </div>
              <div className="contactBox">
                <h2 className="fs36 mb-8 text-center">
                  First, tell us about yourself
                </h2>
                <div className="flex flex-wrap justify-between gap-y-8">
                  {inputArr.map((data, index) => (
                    <div className="formGroup" key={index}>
                      <label className="block text-[14px] uppercase font-bold mb-3">
                        {data.label}{" "}
                        <span className="text-red-700">{data.imp}</span>
                      </label>
                      <input
                        className="h-[48px] p-3 rounded-lg border-[1px] border-[#04B0B0] w-full"
                        type={data.type}
                        placeholder={data.placeholder}
                        name={data.name}
                        value={formData[data.name]} // Controlled input
                        onChange={handleInputChange} // Change handler
                      />
                      {errors[data.name] && isSubmitted && (
                        <p className="text-red-500 text-sm leading-loose absolute">
                          {errors[data.name]}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
                <p className="text-[14px] text-end mt-5">
                  * Fields are required
                </p>
              </div>
            </div>

            <div
              className="contactRow"
              ref={(el) => (contactRows.current[1] = el)}
              data-index="1"
            >
              <div className={`stepIndicator`}>
                <h1>2</h1>
              </div>
              <div className="contactBox">
                <h2 className="fs36 mb-2 text-center">
                  Now, tell us about your project
                </h2>
                <h5 className="fs28 font-semibold mb-8 text-center">
                  What type of service are you looking for?
                </h5>
                <div className="grid md:grid-cols-3 grid-cols-2 md:w-fit mx-auto md:gap-5 gap-[8px]">
                  {serviceArr.map((data, index) => (
                    <div
                      className={`md:text-base text-xs md:min-w-[190px] min-w-[140px] md:h-[55px] h-[50px] flex items-center justify-center border-[#04B0B0] border-2 hover:bg-[#04B0B0] hover:text-white rounded-[10px] cursor-pointer transition-all duration-300 ${
                        serviceIndex === index
                          ? "bg-[#04B0B0] text-white"
                          : "bg-unset text-black"
                      }`}
                      key={index}
                      onClick={() => serviceClick(index)}
                    >
                      {data.name}
                    </div>
                  ))}
                </div>
                {errors["service"] && isSubmitted && (
                  <p className="text-red-500 text-sm leading-loose text-center">
                    {errors["service"]}
                  </p>
                )}
              </div>
            </div>

            <div
              className="contactRow"
              ref={(el) => (contactRows.current[2] = el)}
              data-index="2"
            >
              <div className={`stepIndicator`}>
                <h1>3</h1>
              </div>
              <div className="contactBox">
                <h2 className="fs36 text-center mb-12">
                  Please select your budget
                </h2>
                <RangeSlider
                  value={sliderValue}
                  onPriceChange={handlePriceChange}
                />
              </div>
            </div>

            <div
              className="contactRow"
              ref={(el) => (contactRows.current[3] = el)}
              data-index="3"
            >
              <div className={`stepIndicator`}>
                <h1>4</h1>
              </div>
              <div className="contactBox relative">
                <h2 className="fs36 text-center mb-8 leading-tight">
                  And lastly, give us additional information
                </h2>
                <div className="">
                  <div className="mb-6">
                    <label className="font-semibold text-[14px] mb-3 block uppercase">
                      link to your current website (if you have one)
                    </label>
                    <input
                      type="text"
                      className="h-[48px] p-3 rounded-lg border-[1px] border-[#04B0B0] w-full"
                      name="website"
                      value={formData.website} // Controlled input
                      onChange={handleInputChange} // Change handler
                    />
                  </div>
                  <div className="mb-0">
                    <label className="font-semibold text-[14px] mb-3 block uppercase">
                      project details <span className="text-red-700">*</span>
                    </label>
                    <textarea
                      cols="30"
                      rows="10"
                      className="h-[150px] w-full p-3 rounded-lg border-[1px] border-[#04B0B0]"
                      name="projectDetails"
                      value={formData.projectDetails} // Controlled textarea
                      onChange={handleInputChange} // Change handler
                    ></textarea>
                    {errors["projectDetails"] && isSubmitted && (
                      <p className="text-red-500 text-sm leading-loose">
                        {errors["projectDetails"]}
                      </p>
                    )}

                    <p className="text-sm text-center mt-4 mb-6 font-medium">
                      By submitting, you are agreeing to our{" "}
                      <Link to="" className="underline">
                        Terms &amp; Conditions
                      </Link>{" "}
                      and{" "}
                      <Link to="" className="underline">
                        Privacy Policy
                      </Link>
                    </p>
                    <input
                      type="submit"
                      value="Complete Submition"
                      className={`bg-[#04b0b0] w-fit px-6 py-4 mx-auto block cursor-pointer rounded-[10px] text-white border-2 border-[#04b0b0] font-semibold transition-all duration-300 mb-4 ${
                        !isFormValid ? "disabled" : ""
                      }`}
                      disabled={!isFormValid} // Disabled if the form is not valid
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
      </div>
    </section>
  );
};

export default GetInTouch;
