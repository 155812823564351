import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import "./blog.scss";
import BlogPostJson from './blogPosts.json';
import BlogCard from './BlogCard';

// Function to dynamically import images from a specified directory
const importAllImages = (context) => {
  let images = {};
  context.keys().forEach((item) => {
    images[item.replace('./', '')] = context(item);
  });
  return images;
};

// Dynamically import all images from the assets/images directory
const images = importAllImages(require.context('../../assets/images', false, /\.(png|jpe?g|svg)$/));

const BlogDetail = () => {
  const { slug } = useParams(); // Get the blog slug from URL parameters
  const triggerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const [activeId, setActiveId] = useState(null); // State to track the active section
  const sectionRefs = useRef([]); // Store refs for each section
  const [relatedPosts, setRelatedPosts] = useState([]); // State to store related posts

  // Find the blog post by slug
  const post = BlogPostJson.find((postItem) => postItem.slug === slug);

  useEffect(() => {
    if (post) {
      // Get the category of the current post and normalize it
      const postCategory = post.category.trim().toLowerCase();

      // Get related posts based on category, excluding the current post
      const filteredPosts = BlogPostJson.filter((relatedPost) => {
        // Normalize related post category for comparison
        const relatedCategory = relatedPost.category.trim().toLowerCase();
        const currentPostSlug = post.slug; // Get the current post slug
        // Compare categories and exclude the current post
        return relatedCategory === postCategory && relatedPost.slug !== currentPostSlug;
      });

      // Sort related posts by date to get the latest posts (if the 'date' field exists)
      const sortedPosts = filteredPosts.sort((a, b) => new Date(b.date) - new Date(a.date));

      // Limit the number of related posts to a maximum of 3
      const latestThreePosts = sortedPosts.slice(0, 3);

      console.log("Filtered and Sorted Related Posts:", latestThreePosts);

      // Set the related posts state to the latest 3 posts
      setRelatedPosts(latestThreePosts);

    }
  }, [post]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when component mounts

    const triggerElement = triggerRef.current;

    if (triggerElement) {
      setIsSticky(true); // Activate sticky sidebar when element is mounted

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach(entry => {
            document.body.style.overflow = entry.isIntersecting ? 'visible' : 'hidden';
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 0.1
        }
      );

      observer.observe(triggerElement);

      return () => {
        observer.disconnect();
        setIsSticky(false); // Reset sticky state when component unmounts
        document.body.style.overflow = 'visible';
      };
    } else {
      setIsSticky(false); // Ensure sticky state is false if element doesn't exist
    }
  }, [slug]);

  // Scroll to the section smoothly with an offset when a ToC link is clicked
  const handleScrollToSection = (e, index) => {
    e.preventDefault();
    const targetElement = sectionRefs.current[index];
    if (targetElement) {
      const offsetPosition = targetElement.offsetTop - 100; // Adjust offset based on the navbar height
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);

    // Observer to track sections
    const sectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(parseInt(entry.target.dataset.index, 10)); // Set active section based on index
          }
        });
      },
      {
        root: null, // Use viewport as root
        threshold: 0.9, // Trigger when 60% of section is visible
      }
    );

    // Observe each section using its ref
    sectionRefs.current.forEach((section) => {
      if (section) sectionObserver.observe(section);
    });

    return () => {
      // Cleanup observer
      sectionRefs.current.forEach((section) => {
        if (section) sectionObserver.unobserve(section);
      });
    };
  }, []);

  // Make sure postImage exists
  const postImage = post?.image && images[post.image] ? images[post.image] : '';

  if (!post) {
    return <div>Blog post not found</div>;
  }

  return (
    <section className="py100">
      <div className="container">
        <div className="blogRow grid gap-10">
          {/* Table of Contents (Sticky Sidebar) */}
          <div className="hidden lg:block" ref={triggerRef} style={{ gridArea: 'a' }}>
            <div className={`transition-all duration-300 ${isSticky ? 'sticky min-[1680px]:top-[125px] min-[1370px]:top-[95px] top-[80px]' : 'relative'}`}>
              <h3 className="font-semibold mb-4 fs28">In this post</h3>
              <ul className="space-y-3">
                {post.content.map((section, index) => (
                  <li key={index}>
                    <a
                      href={`#${section.id}`}
                      onClick={(e) => handleScrollToSection(e, index)}
                      className={`fs24 hover:text-[#006363] ${parseInt(activeId, 10) === index ? 'text-[#006363] font-medium' : ''}`}
                    >
                      {section.heading}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Main Content */}
          <div className="prose prose-lg" style={{ gridArea: 'b' }}>
            {/* Title and Meta Information */}
            <h1 className="fs45">{post.title}</h1>
            <p className="text-sm lg:mt-4 lg:mb-9 md:mt-3 md:mb-6 mt-2 mb-4">
              by <span className="text-[#006363] font-medium">{post.author}</span> in{' '}
              <span className="text-[#006363] font-medium">{post.category}</span>
            </p>

            {/* Featured Image */}
            <div className="relative md:h-80 h-52 overflow-hidden rounded-lg md:mb-6 mb-4">
              <img src={postImage} alt={post.title} className="w-full h-full object-cover" />
            </div>

            {/* Blog Pre-content */}
            {post.blogPre?.map((data, index) => (
              <p className="mt-4 first-of-type:mt-0 leading-normal" key={index}>
                {post.excerpt} {data.detail}
              </p>
            ))}

            {/* Blog Content Sections */}
            {post.content.map((section, index) => (
              <div
                key={index}
                ref={(el) => (sectionRefs.current[index] = el)} // Attach ref to the section
                data-index={index} // Index for tracking
              >
                <h2 className="fs40 font-bold md:mt-8 md:mb-4 mt-6 mb-2">{section.heading}</h2>
                {section.blogPost?.map((data, index) => (
                  <p className="mt-4 first-of-type:mt-0 leading-normal" key={index}>
                    {data.detail}
                  </p>
                ))}
                {section.image && (
                  <img src={section.image} alt={section.heading} className="rounded-lg mb-4" />
                )}
              </div>
            ))}
          </div>
        </div>
        {/* Related Posts Section */}
        <div className="pt80">
          <h2 className="text-center fs45 font-semibold lg:mb-10 mb-5">Related Posts</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-8">
            {relatedPosts.length > 0 ? (
              relatedPosts.map((relatedPost, index) => (
                <BlogCard key={index} post={relatedPost} images={images} />
              ))
            ) : (
              <p className="text-center">No related posts available.</p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetail;
