// Filter.js
import React from 'react';

const categories = ["All", "Web Development", "Web Design", "Digital Marketing", "UI/UX Design", "Wordpress", "Shopify", "Mobile Application"];

const Filter = ({ currentCategory, setCategory }) => {
  return (
    <div className="flex justify-center xl:flex-nowrap flex-wrap xl:space-x-4 xl:gap-0 md:gap-4 gap-2 lg:mb-14 mb-8 xl:w-full md:w-3/4 w-full mx-auto">
      {categories.map((category) => (
        <button
          key={category}
          onClick={() => setCategory(category)}
          className={`md:px-4 md:py-2 px-3 py-1 rounded-full md:text-sm text-xs ${currentCategory === category ? 'bg-[#006363] text-white' : 'bg-gray-200 text-gray-700'}`}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default Filter;
