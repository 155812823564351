import React from 'react'

const MagicIcon = ({iconClr, className}) => {
  return (
    <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.2099 4.17203V1.75098M16.2099 18.6983V16.2773M7.73623 10.2247H10.1573M22.2625 10.2247H24.6836M19.5994 13.6141L21.052 15.0668M19.5994 6.83519L21.052 5.38256M1.68359 24.751L12.5783 13.8562M12.8204 6.83519L11.3678 5.38256" stroke={iconClr} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default MagicIcon
