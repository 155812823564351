import React from 'react'

const TwIcon = (props) => {
    return (
        <a href='https://twitter.com/PixelPaceTech' target='_blank'>
            <svg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.17334 12.9867C7.09334 12.9867 8.8 12.5067 10.2933 11.5467C11.6444 10.6578 12.6933 9.48444 13.44 8.02666C14.1511 6.60444 14.5067 5.14666 14.5067 3.65333V3.22666C15.1467 2.76444 15.6978 2.19555 16.16 1.52C15.5556 1.80444 14.9333 1.98222 14.2933 2.05333C15.0044 1.62666 15.4844 1.02222 15.7333 0.239997C15.0578 0.631108 14.3644 0.897775 13.6533 1.04C13.12 0.506663 12.4889 0.177774 11.76 0.0533304C11.0311 -0.0711145 10.3378 0.0444412 9.68 0.399997C9.02223 0.755552 8.54223 1.27111 8.24 1.94666C7.93778 2.62222 7.87556 3.31555 8.05334 4.02666C6.73778 3.95555 5.48445 3.62666 4.29334 3.04C3.10223 2.45333 2.09778 1.64444 1.28 0.61333C0.853337 1.36 0.737781 2.15111 0.933337 2.98666C1.12889 3.82222 1.58223 4.48889 2.29334 4.98666C1.76 4.95111 1.26223 4.80889 0.800004 4.56V4.61333C0.800004 5.39555 1.04889 6.08889 1.54667 6.69333C2.04445 7.29777 2.66667 7.67111 3.41334 7.81333C3.12889 7.92 2.84445 7.97333 2.56 7.97333C2.34667 7.97333 2.15111 7.95555 1.97334 7.92C2.18667 8.56 2.56889 9.09333 3.12 9.52C3.67111 9.94666 4.30223 10.16 5.01334 10.16C4.44445 10.6222 3.80445 10.9778 3.09334 11.2267C2.38223 11.4756 1.67111 11.6 0.960004 11.6C0.675559 11.6 0.408893 11.5822 0.160004 11.5467C1.68889 12.5067 3.36 12.9867 5.17334 12.9867Z" fill={props.iconClr} />
            </svg>



        </a>
    )
}

export default TwIcon
