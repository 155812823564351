import React from 'react'

const PauseIcon = ({iconClr, className}) => {
  return (
    <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.43359 16.3457V9.74565M14.9336 16.3457V9.74565M23.1836 13.0457C23.1836 19.1208 18.2587 24.0457 12.1836 24.0457C6.10846 24.0457 1.18359 19.1208 1.18359 13.0457C1.18359 6.97052 6.10846 2.04565 12.1836 2.04565C18.2587 2.04565 23.1836 6.97052 23.1836 13.0457Z" stroke={iconClr} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default PauseIcon
