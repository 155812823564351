import React from 'react'

const PortfolioArrow = ({className, iconClr}) => {
  return (
    <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.1911 11.9812H1.43609C1.02859 11.9812 0.687377 11.8399 0.412445 11.5572C0.137482 11.2746 0 10.9238 0 10.5049C0 10.086 0.137482 9.73525 0.412445 9.45262C0.687377 9.16999 1.02859 9.02868 1.43609 9.02868H23.1911L16.8869 2.548C16.6021 2.25526 16.4616 1.91269 16.4653 1.52029C16.469 1.12789 16.6095 0.779006 16.8869 0.47364C17.1839 0.168308 17.5252 0.01059 17.9105 0.000485469C18.296 -0.00961901 18.6372 0.138011 18.9342 0.443377L27.5102 9.25957C27.6894 9.44378 27.8159 9.6381 27.8895 9.84251C27.9632 10.0469 28 10.2677 28 10.5049C28 10.7422 27.9632 10.963 27.8895 11.1673C27.8159 11.3718 27.6894 11.5661 27.5102 11.7503L18.9342 20.5665C18.6495 20.8592 18.3113 21.0037 17.9198 20.9999C17.5282 20.9961 17.1839 20.8416 16.8869 20.5362C16.6095 20.2309 16.4659 19.8851 16.4561 19.499C16.4463 19.1129 16.5899 18.7672 16.8869 18.4619L23.1911 11.9812Z" fill={iconClr}/>
    </svg>
    
  )
}

export default PortfolioArrow
