import React, { useEffect, useState } from 'react';
import TopBar from './layout/topBar/TopBar';
import Navbar from './layout/navbar/Navbar';
import Footer from './layout/footer/Footer'
import AppRouter from './Router/Router'
import {
  BrowserRouter as Router, useLocation
} from 'react-router-dom';
import PhoneIcon from './assets/images/PhoneIcon';
import ScrollTop from './Router/ScrollTop';
import Modal from 'react-modal';
import RefreshModal from './components/modal/RefreshModal';
import Preloader from './components/preloader/Preloader';
import CustomCursor from './components/cursor';
import ChatbotBox from './components/chatbot';


// Initialize the modal
Modal.setAppElement('#root');

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Example: Simulate a load (e.g., fetching data)
    setTimeout(() => setLoading(false), 500); // Replace with your actual load logic
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    // console.log("isModalOpen", isOpen);
  };


  const handleHamburger = (event) => {
    event.stopPropagation();
    setIsMenuOpen((isMenuOpen) => !isMenuOpen);
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.classList.add('bgFreeze');
      const handleClickOutside = (e) => {
        const specificSection = document.getElementById('navBarInner');
        const hamburgerButton = document.getElementById('hamburgerButton');

        // Check if the clicked element is not within the specific section
        // and is not the hamburger button
        if (specificSection && !specificSection.contains(e.target) && e.target !== hamburgerButton) {
          setIsMenuOpen(false);
        }
      };
      // Attach the click event listener to the document
      document.addEventListener('click', handleClickOutside);
      // Clean up the event listener when the component unmounts

      return () => {
        document.documentElement.classList.remove('bgFreeze');
        document.removeEventListener('click', handleClickOutside);
      };

    }

  }, [isMenuOpen]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;

      if (scrollTop > 0 && !isScrolled) {
        setIsScrolled(true);
      } else if (scrollTop === 0 && isScrolled) {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  const boxShadow = {
    boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)',
  }

  return (
    <Router>
      {loading && <Preloader />}
      {!loading && (
        <div className='min-h-screen flex flex-col'>
          <RefreshModal isOpen={isOpen} onRequestClose={closeModal} setIsOpen={setIsOpen} />
          <CustomCursor />
          <ChatbotBox />
          <ScrollTop />
          <TopBar />
          <Navbar isScrolled={isScrolled} isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} hamclick={handleHamburger} />
          <AppRouter />
          <Footer />
          <div>
            <a href='tel: +61 417 205 778' className={`flex justify-center items-center bg-[#0FE3E3] w-[50px] h-[50px] rounded-full fixed bottom-[40px] left-[40px] transition-all z-50 max-[991px]:bottom-[20px] max-[991px]:left-[20px] ${isScrolled ? 'opacity-100' : 'opacity-0'}`}>
              <PhoneIcon className='w-5' iconClr='#fff' />
            </a>
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
