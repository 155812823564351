import React from 'react'

const FbIcon = (props) => {
    return (
        <a href='https://www.facebook.com/PixelPaceTechnologies/' target='_blank'>
            <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.14877 9H5.80502V16H2.68002V9H0.117523V6.125H2.68002V3.90625C2.68002 1.40625 4.18002 0 6.46127 0C7.55502 0 8.71127 0.21875 8.71127 0.21875V2.6875H7.43002C6.18002 2.6875 5.80502 3.4375 5.80502 4.25V6.125H8.58627L8.14877 9Z" fill={props.iconClr} />
            </svg>


        </a>
    )
}

export default FbIcon
