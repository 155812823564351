import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/webDevBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
    {
      subtitle: "Custom Mobile App Development:",
      title: "We develop bespoke mobile applications tailored to your specific business needs, ensuring a unique and engaging user experience that stands out in today's crowded app marketplace.",
    },
    {
      subtitle: "Mobile App Optimization:",
      title: "Optimize your mobile app’s performance with our services, including UI/UX enhancements, speed optimization, and compatibility across multiple devices to provide the best user experience.",
    },
    {
      subtitle: "Integration Services:",
      title: "Integrate your mobile app seamlessly with essential third-party services and APIs, enhancing functionality and ensuring smooth operational workflows.",
    },
    {
      subtitle: "Continuous Support and Maintenance:",
      title: "Our dedicated support and maintenance services keep your mobile application up-to-date, secure, and running smoothly, allowing you to focus on scaling your business.",
    },
  ]


const MobApp = () => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
      });
      const serviceBgStyle = {
        '--bg-image': `url(${bgImg})`,
      };
  return (
    <section className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Custom Mobile Application Development | Pixel Pace Technologies'} metaDescription={`Elevate your business with Pixel Pace Technologies. Our custom mobile application development services offer seamless user experiences tailored to your business needs. Contact us for innovative mobile app solutions that drive engagement and growth.`} />
        <ServicePage title="Mobile Application Development for Business Growth" subTitle='Transform Your Business with Cutting-Edge Mobile Apps' detail="At Pixel Pace Technologies, we specialize in crafting high-performance mobile applications that are tailored to meet your business objectives. Whether you are launching a new app or enhancing an existing one, our expert team is dedicated to delivering a seamless user experience that drives customer engagement and business growth." />
        <div className='pt100' ref={ref}>
          <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace for Your Mobile App Needs?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default MobApp
