import React, { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

const SCALE_VALUES = [400, 1650, 2700, 3860, 6000]; // Main scale values
const SUB_TICKS_COUNT = 4; // Number of sub-ticks between main values

// Function to generate full scale values including sub-ticks
const generateFullScaleValues = (scaleValues, subTicksCount) => {
  const fullValues = [];
  
  for (let i = 0; i < scaleValues.length - 1; i++) {
    fullValues.push(scaleValues[i]); // Add main value
    const step = (scaleValues[i + 1] - scaleValues[i]) / (subTicksCount + 1); // Calculate sub-tick step

    // Add sub-ticks
    for (let j = 1; j <= subTicksCount; j++) {
      fullValues.push(scaleValues[i] + j * step);
    }
  }
  
  fullValues.push(scaleValues[scaleValues.length - 1]); // Add the last main value
  return fullValues;
};

// Generate full scale values with sub-ticks included
const FULL_SCALE_VALUES = generateFullScaleValues(SCALE_VALUES, SUB_TICKS_COUNT);

const RangeSlider = ({ onPriceChange, value }) => {
  const [valueIndex, setValueIndex] = useState(0); // Store index in the full scale array

    // Update local state if the parent's value changes
    useEffect(() => {
      setValueIndex(value);
    }, [value]);

  // Function to handle slider movement
  const handleChange = (values) => {
    const newIndex = Math.round(values[0]);
    setValueIndex(newIndex);
    const newPrice = Math.round(FULL_SCALE_VALUES[newIndex]);
    onPriceChange(newPrice, newIndex);
  };

  return (
    <div className="RangeSlider">
      <Range
        values={[valueIndex]}
        step={1} // Step through each value in the full scale array
        min={0} // Index starts from 0
        max={FULL_SCALE_VALUES.length - 1} // Maximum index is the last in the full scale array
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            className="h-[36px] flex w-full relative"
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
            }}
          >
            <div
              className="h-[8px] w-full rounded-[4px] self-center"
              ref={props.ref}
              style={{
                background: getTrackBackground({
                  values: [valueIndex],
                  colors: ['#04B0B0', '#ccc'],
                  min: 0,
                  max: FULL_SCALE_VALUES.length - 1,
                }),
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            className="focus-visible:outline-none h-[24px] w-[24px] rounded-[50%] bg-white flex justify-center items-center"
            {...props}
            style={{
              ...props.style,
              boxShadow: '0px 2px 6px #AAA',
            }}
          >
            <div
              className="absolute top-[-28px] text-white font-bold text-[12px] py-1 px-2 rounded-[4px] bg-[#04B0B0]"
            >
              ${Math.round(FULL_SCALE_VALUES[valueIndex])} {/* Show value for current index */}
            </div>
            <div
              className="h-[16px] w-[5px]"
              style={{
                backgroundColor: isDragged ? '#04B0B0' : '#CCC',
              }}
            />
          </div>
        )}
      />
      <div className="flex justify-between w-full text-black relative">
        {/* Render Main Scale Values */}
        {SCALE_VALUES.map((scale, index) => (
          <div
            className="text-center"
            key={index}
            style={{
              position: 'absolute',
              transform: 'translateX(-50%)',
              left: `${(index / (SCALE_VALUES.length - 1)) * 100}%`,
            }}
          >
            <span className="text-[12px]">{scale}</span>
          </div>
        ))}
        
        {/* Render Sub-tick marks visually */}
        {FULL_SCALE_VALUES.map((_, index) => (
          <div
            key={index}
            style={{
              position: 'absolute',
              transform: 'translateX(-50%)',
              left: `${(index / (FULL_SCALE_VALUES.length - 1)) * 100}%`,
            }}
          >
            {index % (SUB_TICKS_COUNT + 1) !== 0 && <span className="text-[8px] opacity-50">|</span>} {/* Sub-tick mark */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RangeSlider;
