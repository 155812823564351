import React from 'react'
import BrowserTabTitle from '../../components/browserTabTitle'
import GetInTouch from './getInTouch'
const ContactUsPage = () => {
  return (
    <div>
      <BrowserTabTitle tabTitle={'Contact us - Pixel Pace Technologies'}/>
   <GetInTouch/>
    </div>
  )
}

export default ContactUsPage



