import React from 'react'
import PortfolioBanner from './PortfolioBanner'
import BookAppoinment from '../indexPage/BookAppoinment'
import PortfolioWrap from '../indexPage/portfolioWrap/PortfolioWrap'
// import TrustedClient from '../indexPage/trustedClient/TrustedClient'
import Quote from '../indexPage/Quote'
// import Testimonial from '../indexPage/testimonial/Testimonial'
import ContactUs from '../contactUS/ContactUs'
import BrowserTabTitle from '../../components/browserTabTitle'

const PortfolioPage = () => {
    return (
        <>
          <BrowserTabTitle tabTitle={'Explore Our Website Design & Digital Marketing Portfolio | Pixel Pace Technologies'} metaDescription={`Dive into our comprehensive portfolio at Pixel Pace Technologies and see how our expertise in website design and digital marketing has propelled businesses to success. Discover innovative solutions tailored to each client’s needs. Start your journey with us today!`} />
          <PortfolioBanner />
          <BookAppoinment/>
          <PortfolioWrap/>
          {/* <TrustedClient/> */}
          <Quote/>
          {/* <Testimonial/> */}
          <ContactUs/>
        </>
    )
}

export default PortfolioPage
