import React from "react";
import ThumbsUp from "../../../assets/images/ThumbsUp";
import { Link } from "react-router-dom";
import Button from "../../../components/button/Button";

const Thankfulness = () => {
  return (
    <section className="flex flex-col flex-1 py100 justify-center text-center">
      <div class="container">
        <div className="flex gap-2 justify-center">
          <h1 className="text-6xl text-[#04B0B0]">Thank You!</h1>
          <ThumbsUp className="w-14 h-fit" iconClr="#04B0B0" />
        </div>
        <p className="fs16 my-4">
          Your submission has been received successfully. <br />
          We will get back to you soon.
        </p>
        <Link to="/">
          <Button btnTxt="Go Home" className="before:bg-[#04B0B0] py-4 px-6 w-fit mx-auto text-white bg-black rounded-[8px] before:rounded-[10px]"/>
        </Link>
      </div>
    </section>
  );
};

export default Thankfulness;
