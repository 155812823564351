import React from 'react'

const PhoneIcon = ({iconClr, className}) => {
    return (
        <a href="tel:+61 417 205 778"> <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.878 18C14.8826 18 12.8779 17.5361 10.8641 16.6083C8.85034 15.6804 6.99842 14.3715 5.30837 12.6814C3.62512 10.9914 2.31958 9.14117 1.39175 7.13077C0.463916 5.12036 0 3.11742 0 1.12196C0 0.804311 0.105883 0.53791 0.317648 0.322757C0.529413 0.107585 0.79412 0 1.11177 0H4.56513C4.83255 0 5.06842 0.0872211 5.27272 0.261663C5.47702 0.436104 5.60699 0.651603 5.66263 0.908157L6.26942 4.02351C6.31151 4.31266 6.30268 4.56108 6.24295 4.76877C6.18323 4.97646 6.07599 5.15089 5.92122 5.29207L3.47576 7.67239C3.86943 8.3932 4.3191 9.07499 4.82476 9.71775C5.33042 10.3605 5.87781 10.9745 6.46694 11.5595C7.04796 12.1405 7.66561 12.6801 8.31991 13.1783C8.97422 13.6765 9.68078 14.1401 10.4396 14.569L12.8159 12.1724C12.9815 12 13.182 11.8792 13.4176 11.81C13.6531 11.7408 13.8978 11.7238 14.1516 11.7591L17.0918 12.3577C17.3593 12.4283 17.5775 12.5647 17.7465 12.767C17.9155 12.9693 18 13.1987 18 13.4553V16.8882C18 17.2059 17.8924 17.4706 17.6772 17.6824C17.4621 17.8941 17.1957 18 16.878 18Z" fill={iconClr} />
        </svg>
        </a>
    )
}

export default PhoneIcon
