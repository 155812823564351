import React from 'react'

const LinkedInIcon = (props) => {
    return (
        <a href='https://www.linkedin.com/company/pixel-pace-technologies/about/' target='_blank'>
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.40503 14H0.498779V4.65625H3.40503V14ZM1.93628 3.40625C1.03003 3.40625 0.280029 2.625 0.280029 1.6875C0.280029 0.78125 1.03003 0.03125 1.93628 0.03125C2.87378 0.03125 3.62378 0.78125 3.62378 1.6875C3.62378 2.625 2.87378 3.40625 1.93628 3.40625ZM14.2488 14H11.3738V9.46875C11.3738 8.375 11.3425 7 9.84253 7C8.34253 7 8.12378 8.15625 8.12378 9.375V14H5.21753V4.65625H7.99878V5.9375H8.03003C8.43628 5.21875 9.37378 4.4375 10.78 4.4375C13.7175 4.4375 14.28 6.375 14.28 8.875V14H14.2488Z" fill={props.iconClr} />
            </svg>



        </a>
    )
}

export default LinkedInIcon
