import React from 'react'
import ClockIcon from '../../assets/images/ClockIcon'
import FbIcon from '../../assets/images/FbIcon'
import TwIcon from '../../assets/images/TwIcon'
import LinkedInIcon from '../../assets/images/LinkedInIcon'
import PhoneIcon from '../../assets/images/PhoneIcon'
import EmailIcon from '../../assets/images/EmailIcon'
import InstaIcon from '../../assets/images/InstaIcon'
import WhatsappIcon from '../../assets/images/WhatsappIcon'
import TikTokIcon from '../../assets/images/TikTokIcon'
import ThreadIcon from '../../assets/images/ThreadIcon'


const TopBar = () => {
    return (
        <section className='bg-[#CAFFFF] text-[#1D2C38] rubikFont font-normal'>
            <div className="container flex justify-between items-center h-[52px] max-[1680px]:h-[38px] max-[1370px]:h-[34px] ">
                <ul className='flex items-center gap-7 max-[991px]:gap-2'>
                    <li className='md:block hidden animate__animated animate__fadeInUp'>
                        <h6 className='max-[1370px]:text-[14px] max-[991px]:text-[12px]'>Call Us: <a href="tel:+61 417 205 778" className='text-[14px] max-[1370px]:text-[12px]'>+61 417 205 778</a></h6>
                    </li>
                    <li className='md:hidden animate__animated animate__fadeInUp'>
                      <PhoneIcon className='w-[12px]' iconClr='#000' />
                    </li>
                    <li className='md:hidden animate__animated animate__fadeInUp'>
                      <EmailIcon className='w-[12px]' iconClr='#000' />
                    </li>
                    <li className='md:block hidden animate__animated animate__fadeInUp'>
                        <h6 className='max-[1370px]:text-[14px] max-[991px]:text-[12px]'>Email Us: <a href="mailto:info@pixelpacetechnologies.com" className='text-[14px] underline max-[1370px]:text-[12px]'>info@pixelpacetechnologies.com</a></h6>
                    </li>
                </ul>
                <ul className='flex items-center gap-7 max-[991px]:gap-2'>
                    <li className='md:flex gap-1 items-center hidden animate__animated animate__fadeInUp'>
                        <ClockIcon iconClr="#1D2C38"/>
                        <h6 className='max-[1370px]:text-[14px] max-[991px]:text-[12px]'>09:00 am - 05:00 pm (GMT+11)</h6>
                    </li>
                    <li className='flex gap-4 items-center max-[1370px]:gap-3 max-[991px]:gap-2 animate__animated animate__fadeInUp'>
                        <FbIcon iconClr="#1D2C38" />
                        <TwIcon iconClr="#1D2C38"/>
                        <LinkedInIcon iconClr="#1D2C38"/>
                        <InstaIcon iconClr="#1D2C38" />
                        <WhatsappIcon iconClr="#1D2C38" />
                        <TikTokIcon iconClr="#1D2C38" />
                        <ThreadIcon iconClr="#1D2C38" />
                    </li>
                </ul>
            </div>
        </section>
    )
}

export default TopBar
