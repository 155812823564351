import React from 'react'

const DiscoveryIcon = ({className}) => {
    return (
        <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 105 93" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M43.4492 92.4024C43.4492 84.3069 46.6651 76.543 52.3895 70.8187C58.1138 65.0943 65.8777 61.8784 73.9732 61.8784C82.0687 61.8784 89.8326 65.0943 95.5569 70.8187C101.281 76.543 104.497 84.3069 104.497 92.4024" fill="#000" />
            <path d="M97.5284 85.4356C97.5284 77.3401 94.3125 69.5762 88.5882 63.8519C82.8638 58.1275 75.0999 54.9116 67.0045 54.9116C58.909 54.9116 51.1451 58.1275 45.4207 63.8519C39.6964 69.5762 36.4805 77.3401 36.4805 85.4356H97.5284Z" stroke="#fff" strokeWidth="3.5" strokeMiterlimit="10" strokeLinecap="round" />
            <path d="M16.8673 58.81C16.8044 58.7118 16.745 58.6191 16.6875 58.5174C15.7957 56.9804 15.5454 55.1539 15.9907 53.4336C16.4361 51.7134 17.5412 50.2378 19.0668 49.3266C20.5923 48.4154 22.4155 48.1418 24.1413 48.5653C25.8671 48.9887 27.3565 50.075 28.2871 51.5889C29.2176 53.1027 29.5143 54.9223 29.1128 56.6533C28.7113 58.3843 27.644 59.8875 26.1421 60.8372C24.6402 61.7869 22.8246 62.1067 21.0886 61.7272C19.3526 61.3478 17.836 60.2997 16.8673 58.81V58.81Z" stroke="#fff" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M56.8405 33.1881C56.8151 33.1505 56.7924 33.111 56.7726 33.0701L56.7306 33.0047C55.8415 31.5184 55.5617 29.7459 55.9498 28.0581C56.3379 26.3702 57.3638 24.8979 58.8127 23.9493C60.2617 23.0007 62.0214 22.6492 63.7236 22.9685C65.4258 23.2879 66.9385 24.2532 67.9451 25.6625C68.0135 25.7572 68.0729 25.8499 68.1358 25.9481C69.0959 27.4459 69.4217 29.2638 69.0414 31.0018C68.6612 32.7399 67.6061 34.2557 66.1082 35.2157C64.6104 36.1758 62.7925 36.5016 61.0544 36.1213C59.3164 35.7411 57.8006 34.686 56.8405 33.1881V33.1881Z" stroke="#fff" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M16.6886 58.5174C12.4402 51.7216 11.043 43.5246 12.8001 35.7051C14.5572 27.8856 19.3269 21.0744 26.0743 16.7495C32.8216 12.4246 41.0023 10.9348 48.8412 12.6034C56.68 14.2721 63.5447 18.9645 67.9456 25.6626C66.939 24.2533 65.4263 23.288 63.7241 22.9687C62.0219 22.6493 60.2622 23.0008 58.8132 23.9494C57.3643 24.898 56.3384 26.3704 55.9503 28.0582C55.5622 29.746 55.842 31.5185 56.7311 33.0048C54.2298 29.3071 50.3771 26.7388 46.0018 25.8524C41.6264 24.9661 37.0779 25.8323 33.3347 28.265C29.5915 30.6976 26.9525 34.5022 25.9855 38.8604C25.0185 43.2187 25.8007 47.7824 28.1638 51.5699C27.2189 50.0983 25.7367 49.0538 24.0331 48.6589C22.3294 48.264 20.5389 48.5498 19.0429 49.4556C17.5469 50.3613 16.4637 51.8154 16.024 53.508C15.5842 55.2006 15.8227 56.998 16.6886 58.5174V58.5174Z" stroke="#fff" strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default DiscoveryIcon
