import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/WordpressBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';
const dataArr = [
  {
    subtitle: "Expertise and Experience: ",
    title: "Our team has years of experience in delivering high-quality WordPress solutions that power successful websites across various industries.",
  },
  {
    subtitle: "Customizable and Scalable:",
    title: "We build WordPress sites that are not only tailored to your current needs but are also scalable to grow with your business.",
  },
  {
    subtitle: "Ongoing Support and Maintenance:",
    title: "Ensure your WordPress site remains updated, secure, and performing at its best with our continuous support and maintenance services.",
  },
]
const Wordpress = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
});
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <div className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Expert WordPress Development Services | Pixel Pace Technologies'} metaDescription={`Maximize your website's potential with Pixel Pace Technologies' WordPress development services. Tailored solutions for bloggers, businesses, and creative professionals to enhance digital presence with a seamless, user-friendly WordPress platform. Contact us for a free consultation today!`} />
        <ServicePage title="WordPress Services: Empowering Your Digital Presence" subTitle='Tailored WordPress Solutions for Every Need' detail="At Pixel Pace Technologies, we understand the importance of a robust digital presence. Our WordPress services are designed to meet the diverse needs of content managers, bloggers, business owners, and creative professionals. We leverage the power and flexibility of WordPress to create websites that are not only visually appealing but also functionally rich." />
        <div className='pt100' ref={ref}>
        <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace for WordPress Services?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default Wordpress
