import React from 'react'

const RightChevron = ({className,iconClr}) => {
  return (
<svg className={`${className}`} width="100%" height="100%" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.212 16.9583L1.40202 3.14834C1.0377 2.78403 0.860235 2.35038 0.869616 1.8474C0.879027 1.3444 1.06589 0.910756 1.4302 0.546472C1.79452 0.182159 2.22816 0 2.73114 0C3.23411 0 3.66776 0.182159 4.03207 0.546472L18.0677 14.6102C18.3991 14.9417 18.6447 15.313 18.8045 15.7243C18.9643 16.1357 19.0442 16.547 19.0442 16.9583C19.0442 17.3696 18.9643 17.7809 18.8045 18.1923C18.6447 18.6036 18.3991 18.9749 18.0677 19.3063L4.00388 33.3701C3.63957 33.7344 3.21061 33.9119 2.71702 33.9025C2.22346 33.8931 1.79452 33.7062 1.4302 33.3419C1.06589 32.9776 0.883732 32.544 0.883732 32.041C0.883732 31.538 1.06589 31.1044 1.4302 30.7401L15.212 16.9583Z" fill={iconClr}/>
</svg>

  )
}

export default RightChevron
