import React, { useRef, useEffect } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const statArr = [
  {
    statImg: require("../../assets/images/Award_img.gif"),
    title: "125+",
    detail: "Awards Received",
    animate: 'fadeInUp',
    alt: "Award winning",
  },
  {
    statImg: require("../../assets/images/coffee.gif"),
    title: "2500+",
    detail: "Cups of Coffee",
    animate: 'fadeInUp',
    alt: "Cups of coffee",
  },
  {
    statImg: require("../../assets/images/project_complete.gif"),
    title: "2000+",
    detail: "Projects Completed",
    animate: 'fadeInUp',
    alt: "Projects completed",
  },
  {
    statImg: require("../../assets/images/happy_clients.gif"),
    title: "95%",
    detail: "Happy Clients",
    animate: 'fadeInUp',
    alt: "Happy clients",
  },
];

const Statistics = () => {
  const elementRefs = useRef([]);

  useEffect(() => {
    const timers = [];

    if (elementRefs.current.length === statArr.length) {
      statArr.forEach((stat, index) => {
        const value = parseInt(stat.title); // Parsing the numeric part of title
        const endValue = isNaN(value) ? 0 : value; // Handle non-numeric values gracefully
        let current = 0;
        const duration = 1000; // Animation duration in milliseconds
        const increment = endValue > 0 ? Math.ceil(endValue / (duration / 20)) : 0; // Adjust increment for smoother animation

        const timer = setInterval(() => {
          current += increment;
          if (current >= endValue) {
            clearInterval(timer);
            current = endValue;
          }
          if (elementRefs.current[index]) { // Check if the ref is populated
            elementRefs.current[index].textContent = `${current}${stat.title.replace(/[0-9]/g, '')}`;
          }
        }, 50); // Adjust interval for smoother animation

        timers.push(timer); // Save the timer to clear it later
      });
    }

    return () => {
      // Cleanup all timers when component unmounts or if effect re-runs
      timers.forEach(timer => clearInterval(timer));
    };
  }, [statArr]); // Run effect when statArr changes

  return (
    <div className="container relative min-[1690px]:mt-[230px] min-[1370px]:mt-[185px] min-[1200px]:mt-[150px] md:mt-[130px] mt-[175px]">
      <div className='bg-[#DFFFFF] md:rounded-[20px] absolute bottom-0 translate-y-1/2 -translate-x-1/2 left-1/2 rounded-[10px] lg:w-[85%] w-[95%] overflow-hidden'>
        <div className='lg:w-[85%] w-[95%] mx-auto flex items-center md:justify-between justify-center xl:py-9 py-6 md:flex-nowrap flex-wrap md:gap-0 gap-4'>
          {statArr.map((stat, index) => (
            <ScrollAnimation
              key={index}
              animateIn={`${stat.animate}`}
              animateOnce={true}
              className='text-center md:w-full w-[47%]'
            >
              <div className='w-[74px] h-[74px] mx-auto max-[1680px]:w-[60px] max-[1680px]:h-[60px] max-[1370px]:w-[45px] max-[1370px]:h-[45px] max-[575px]:w-[35px] max-[575px]:h-[35px]'>
                <img src={stat.statImg} className='h-full object-cover' alt={stat.alt} />
              </div>
              <h2 ref={el => elementRefs.current[index] = el} className='my-4 max-[1680px]:my-2 fs45 font-bold text-black nunitoFont max-[1370px]:mb-0'>
                {stat.title} {/* Initial content */}
              </h2>
              <p className='text-black fs22 font-medium'>{stat.detail}</p>
            </ScrollAnimation>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statistics;
