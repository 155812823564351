import React from 'react'
import './footer.scss'
import logoWhite from '../../assets/images/PP_logoWhite.svg'
import ClockIcon from '../../assets/images/ClockIcon'
import LocationIcon from '../../assets/images/LocationIcon'
import PhoneIcon from '../../assets/images/PhoneIcon'
import EmailIcon from '../../assets/images/EmailIcon'
import SendBtnIcon from '../../assets/images/SendBtnIcon'
import FbIcon from '../../assets/images/FbIcon'
import TwIcon from '../../assets/images/TwIcon'
import InstaIcon from '../../assets/images/InstaIcon'
import WhatsappIcon from '../../assets/images/WhatsappIcon'
import LinkedInIcon from '../../assets/images/LinkedInIcon'
import TikTokIcon from '../../assets/images/TikTokIcon'
import ThreadIcon from '../../assets/images/ThreadIcon'
import { Link, useNavigate, useLocation } from 'react-router-dom';
const upperLayerArr = [
    {
        imgSrc: require('../../assets/images/phoneFooter.svg').default,
        title: "Call us",
        detail: <a href='tel: +61 417 205 778'>
            +61 417 205 778
        </a>,
        animate: "fadeInUp",
        alt: "Call us",
    },
    {
        imgSrc: require('../../assets/images/emailFooter.svg').default,
        title: "Email us:",
        detail:
            <a href='mailto:info@pixelpacetechnologies.com'>
                info@pixelpacetechnologies.com
            </a>,
        animate: "fadeInUp",
        alt: "Email us:",
    },
    {
        imgSrc: require('../../assets/images/timerFooter.svg').default,
        title: "Office hours",
        detail: "Mon-Sat 9:00 - 7:00",
        animate: "fadeInUp",
        alt: "Office hours",
    },
]
const pageLiArr = [
    {
        title: "Useful links",
        subTitle: "Service",
        link: '/',
    },
    {
        subTitle: "Portfolio",
        link: '/Portfolio',
    },
    // {
    //     subTitle: "Contact us",
    //     link: '/ContactUs',
    // },
    {
        subTitle: "About Us",
    },

]
const contactLiArr = [
    {
        title: "Contacts",
        subTitle: "Balwyn North, VIC 3104, Melbourne, Australia",
        imgSrc: <LocationIcon iconClr="#ffffffb3" />,
    },
    {
        subTitle: "Mon-Sat 9:00 - 7:00",
        imgSrc: <ClockIcon iconClr="#ffffffb3" />,
    },
    {
        subTitle: "+61 417 205 778",
        imgSrc: <PhoneIcon className='w-[15px]' iconClr="#ffffffb3" />,
    },
    {
        subTitle: "info@pixelpacetechnologies.com",
        imgSrc: <EmailIcon iconClr="#ffffffb3" />,
    },
]
const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const backtoTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }

    function scrollToSection(target) {
        const section = document.getElementById(target);

        if (section) {
            let offsetY = 0; // Default offset

            if (window.innerWidth < 1950) {
                offsetY = 100; // Adjust offset for smaller screens
            }
            if (window.innerWidth < 1690) {
                offsetY = 80; // Adjust offset for smaller screens
            }
            if (window.innerWidth < 1390) {
                offsetY = 60; // Adjust offset for smaller screens
            }
            if (window.innerWidth < 568) {
                offsetY = 50; // Adjust offset for smaller screens
            }
            const sectionPosition = section.getBoundingClientRect().top + window.scrollY - offsetY;
            window.scrollTo({
                top: sectionPosition,
                behavior: 'smooth',
            });
        }
    }

    const handleAboutUsClick = () => {
        if (location.pathname !== '/') {
            navigate('/');  // Navigate to home first
        }
        setTimeout(() => {
            scrollToSection('aboutUsSec');  // Then scroll to About Us section
        }, 100);  // Wait for the navigation to complete
    };

    return (
        <div className='footerWrap pt100 overflow-hidden'>
            <div className="container">
                <div className="upperLayer">
                    {
                        upperLayerArr.map((upperLayerData, index) => (
                            <div className='flex items-center gap-4' key={index}>
                                <div className='bg-[#04B0B0] w-[40px] h-[40px] rounded-full flex_align max-[1370px]:w-[35px] max-[1370px]:h-[35px]'>
                                    <img className='max-[1370px]:w-[15px]' src={upperLayerData.imgSrc} alt={upperLayerData.alt} />
                                </div>
                                <div>
                                    <h6 className='text-[#BDC2CB] fs16 mb-2'>{upperLayerData.title}</h6>
                                    <p className='text-white fs18 font-semibold'>{upperLayerData.detail}</p>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <div className="mainLayer py80">
                    <div className='footerBox'>
                        <Link onClick={backtoTop} to='/' className='block max-w-[194px] max-[1680px]:max-w-[155px] w-full mb-6 max-[1500px]:max-w-[120px] max-[1500px]:mb-3 max-[1370px]:max-w-[95px]'>
                            {/* <MainLogo iconClr="#fff" /> */}
                            <img src={logoWhite} alt="Pixel Pace Technologies | Website Design & Digital Marketing Services" />
                        </Link>
                        <p className='text-white rubikFont leading-relaxed fs18'>
                            At Pixel Pace Technologies, we blend technology and creativity to deliver exceptional digital experiences. Specializing in Web Development, Design, Marketing, UX/UI, WordPress, and Shopify solutions, we ensure your brand’s online presence is second to none.
                        </p>

                    </div>
                    <div className='footerBox'>
                        {
                            pageLiArr.map((pageLiData, index) => (
                                <div key={index}  >
                                    {pageLiData.title && <h2 className='fs24 font-semibold mb-6 max-[1500px]:mb-3'>{pageLiData.title}</h2>}
                                    {pageLiData.link ? (
                                        <Link to={pageLiData.link} className='fs18 text-white opacity-70 block mb-3 hover:opacity-100'>{pageLiData.subTitle}</Link>
                                    ) : (
                                        <button onClick={handleAboutUsClick} className='text-white opacity-70 block mb-2 fs18 hover:opacity-100'>{pageLiData.subTitle}</button>
                                        
                                    )}
                                </div>
                            ))
                        }
                    </div>
                    <div className='footerBox'>
                        {
                            contactLiArr.map((contactLiData, index) => (
                                <div key={index}>
                                    {contactLiData.title && <h2 className='fs24 font-semibold mb-6  max-[1500px]:mb-3'>{contactLiData.title}</h2>}
                                    <div className='flex gap-4 mb-5 items-center max-[1680px]:gap-3 max-[1370px]:gap-2 max-[1370px]:mb-3'>
                                        <div className='min-w-[15px] min-h-[15px] w-[15px] h-[15px]'>
                                            {contactLiData.imgSrc}
                                        </div>
                                        <Link to={contactLiData.link} className='text-white opacity-70 block fs18'>{contactLiData.subTitle}</Link>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                    {/* <div className='footerBox'>
                    <h2 className='fs24 font-semibold mb-6 max-[1500px]:mb-3'>Subscribe</h2>
                    <p className='text-white opacity-70 fs18'>Get Latest newsletter by <br /> subscribing us!</p>
                       
                        <div className='formGroup'>
                            <input type="text" placeholder='Your email address' />
                            <a href=""><SendBtnIcon iconClr='#fff' /></a>
                        </div>
                    </div> */}
                </div>
                <div className="lastLayer">
                    <div className='flex gap-5 items-center'>
                        <FbIcon iconClr='#fff' />
                        <TwIcon iconClr='#fff' />
                        <LinkedInIcon iconClr='#fff' />
                        <InstaIcon iconClr="#fff" />
                        <WhatsappIcon iconClr="#fff" />
                        <TikTokIcon iconClr="#fff" />
                        <ThreadIcon iconClr="#fff" />
                    </div>
                    <div>
                        <p className='text-white fs18'>© Copyright 2024,  All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
