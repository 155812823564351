// BlogCard.js
import React from 'react';
import { Link } from 'react-router-dom';
import './blog.scss'


const BlogCard = ({ post, images }) => {
    // Access the image dynamically from the images object using the image filename from the post data
    const postImage = images[post.image];
    const authorImage = images[post.authorImg];

    return (
        <Link to={`/blog/${post.slug}`} className="blogCard rounded-[10px] overflow-hidden transition-shadow duration-300 flex flex-col">
            <img src={postImage} alt={post.title} className="w-full h-56 object-cover" />
            <div className="py-4 px-5 flex flex-col justify-between h-full">
                <span className="text-sm text-gray-500">{post.category}</span>
                <h3 className="fs40 font-semibold mt-2 mb-3">{post.title}</h3>
                <p className="text-gray-600 mb-5">{post.excerpt}...</p>
                <div className="flex gap-[10px] items-center">
                    <img src={authorImage} alt="" className="w-10 h-10 rounded-full min-w-[40px] object-cover object-top" />
                    <p className='text-sm w-full'>by {post.author}</p>
                    <Link to={`/blog/${post.slug}`} className="text-[#006363] leading-snug hover:underline w-full text-end font-semibold">Read more</Link>
                </div>
            </div>
        </Link>
    );
};

export default BlogCard;
