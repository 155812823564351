import React, { useState, useEffect, useRef } from 'react'
import './portfolio.scss'
import Button from '../../../components/button/Button';
import RightChevron from '../../../assets/images/RightChevron';
import LeftChevron from '../../../assets/images/LeftChevron';

const LightBox = ({ images, selectedImage, closeLightbox }) => {
    const [currentIndex, setCurrentIndex] = React.useState(images.findIndex(img => img.imgSrc === selectedImage));
    const [currentLink, setCurrentLink] = useState(images[currentIndex].link);

    useEffect(() => {
        setCurrentLink(images[currentIndex].link);
    }, [currentIndex, images]);
    useEffect(() => {
        resetScrollPosition();
      }, [currentIndex]);

    const goToPrevious = () => {
        const isFirstImage = currentIndex === 0;
        const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };

    const goToNext = () => {
        const isLastImage = currentIndex === images.length - 1;
        const newIndex = isLastImage ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);
    };
    const lightboxContainerRef = useRef(null);
    const resetScrollPosition = () => {
        if (lightboxContainerRef.current) {
            lightboxContainerRef.current.scrollTop = 0;
        }
    };


    return (
        <div className="lightbox-backdrop" onClick={closeLightbox}>
            <div className="lightbox-content" onClick={e => e.stopPropagation()}>
                <Button btnLink={currentLink} target='_blank' rel="noreferrer" btnTxt='Preview' className='text-[14px] rounded-[6px] py-2 px-4 w-fit bg-[#CAFFFF] ms-auto mb-2' />
                {/* <span onClick={closeLightbox}>
                    <CrossIcon className="lightbox-close" iconClr="#fff"  />
                </span> */}
                <div className="lightBoxImg" ref={lightboxContainerRef}>
                    <img src={images[currentIndex].imgSrc} alt="lightbox" />
                </div>
                <button className="lightbox-prev" onClick={goToPrevious}>
                    <LeftChevron className="w-[16px] h-[16px]" iconClr='#CAFFFF' />
                </button>
                <button className="lightbox-next" onClick={goToNext}>
                    <RightChevron className="w-[16px] h-[16px]" iconClr='#CAFFFF' />
                </button>
            </div>
        </div>
    )
}

export default LightBox
