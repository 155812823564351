import React, { useState, useEffect } from 'react'
import PricingArrow from '../../../assets/images/PricingArrow';
import './pricingBanner.scss'
import InfinityIcon from '../../../assets/images/InfinityIcon'
import MagicIcon from '../../../assets/images/MagicIcon'
import ChartIcon from '../../../assets/images/ChartIcon'
import PauseIcon from '../../../assets/images/PauseIcon'
import UpgradeIcon from '../../../assets/images/UpgradeIcon'
import PortfolioArrow from '../../../assets/images/PortfolioArrow'
import Button from '../../../components/button/Button'
import ScrollAnimation from 'react-animate-on-scroll';

const tabsData = [
    {
        id: "pills1",
        label: "Long-term contract",
        animate: 'fadeInUp',

        stepArr: [
            {
                id: 1,
                stepIndex: "1",
                price: '$1000/m',
                btnTxt: 'Book a call',
                featureArr: "featureArr1",
            },
            {
                id: 2,
                stepIndex: "2",
                price: '$2000/m',
                btnTxt: 'Book a call',
                featureArr: "featureArr1",
            },
            {
                id: 3,
                stepIndex: "3",
                price: '$4000/m',
                btnTxt: 'Book a call',
                featureArr: "featureArr1",
            },
            {
                id: 4,
                stepIndex: "4",
                price: '$8000/m',
                btnTxt: 'Book a call',
                featureArr: "featureArr1",
            },
            {
                id: 5,
                stepIndex: "5",
                price: '$16000/m',
                btnTxt: 'Book a call',
                featureArr: "featureArr1",
            },
            {
                id: 6,
                stepIndex: "More",
                price: 'Custom',
                btnTxt: 'Get a Quote',
                featureArr: "featureArr1",
            },
        ],
    },
    {
        id: "pills2",
        label: <label>Short-term contract <span className='mobile_none'>(Fixed prices) </span></label>,
        animate: 'fadeInUp',

        stepArr: [
            {
                id: 1,
                price: '$1499',
                btnTxt: 'Book a call',
                stepIndex: "Web Development",
                serviceT: 'Web Development',
                featureArr: "featureArr2",
            },
            {
                id: 2,
                price: '$999',
                btnTxt: 'Book a call',
                stepIndex: "Web Design",
                serviceT: 'Web Design',
                featureArr: "featureArr3",
            },
            {
                id: 3,
                price: '$499',
                btnTxt: 'Book a call',
                stepIndex: "Wordpress",
                serviceT: "Wordpress",
                featureArr: "featureArr4",
            },
            {
                id: 4,
                price: '$499',
                btnTxt: 'Book a call',
                stepIndex: "Shopify",
                serviceT: "Shopify",
                featureArr: "featureArr5",
            },
            {
                id: 5,
                price: '$699',
                btnTxt: 'Book a call',
                stepIndex: "Marketing",
                serviceT: "Marketing",
                featureArr: "featureArr6",
            },
            {
                id: 6,
                price: '$1999',
                btnTxt: 'Book a call',
                stepIndex: "UI UX Design",
                serviceT: "UI UX Design",
                featureArr: "featureArr7",
            },
            {
                id: 7,
                price: '$2999',
                btnTxt: 'Book a call',
                stepIndex: "NFT Website",
                serviceT: "NFT Website",
                featureArr: "featureArr8",
            },
        ],
    },
];

// Define the unique featureArr for each step
const featureArrMap = {
    "featureArr1": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Design Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Development Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Revisions',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Brands',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated Project Manager',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Daily Updates & Progress Reports',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Pause or Cancel Anytime',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Upgrade or Downgrade Anytime',
        },
    ],
    "featureArr2": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Development Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Scalable Web Architectures',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Full-stack Expertise',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Latest Tech Stack Implementation',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated Development Team',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Regular Progress Updates',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Flexible Engagement Models',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Cancel or Modify Anytime',
        },
    ],
    "featureArr3": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Design Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Modern and Responsive Designs',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'User-Centric Approach',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Cross-Platform Compatibility',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated Design Team',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Daily Design Updates',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Complete Brand Integration',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Easy Plan Adjustments Anytime',
        },
    ],
    "featureArr4": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Custom WordPress Development Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Theme Customization and Plugin Integration',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'SEO-Optimized Designs',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'WordPress-specific Security Solutions',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated WordPress Experts',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Regular Maintenance and Updates',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Hassle-free Plan Changes',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Comprehensive WordPress Support',
        },
    ],
    "featureArr5": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Shopify Store Customization Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'E-commerce Optimized Designs',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Conversion-Focused Approach',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Seamless Third-party Integrations',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated Shopify Professionals',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Continuous Store Updates',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Flexible Subscription Options',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Expert Guidance on Shopify Features',
        },
    ],
    "featureArr6": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited Marketing Campaign Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Multi-channel Marketing Solutions',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Data-Driven Strategies',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Dedicated Marketing Team',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Regular Performance Analysis',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Brand-specific Campaign Design',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Flexible Marketing Plans',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Real-time Adjustments and Optimizations',
        },
    ],
    "featureArr7": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited UI/UX Design Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'User Research and Persona Development',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Interactive Prototypes',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Accessibility and Usability Focus',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated UI/UX Design Team',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Continuous User Feedback Integration',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Adaptive Design Strategies',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'Flexible Design Project Scheduling',
        },
    ],
    "featureArr8": [
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Unlimited NFT Website Development Requests',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Blockchain Integration and Wallet Support',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'NFT Showcase and Auction Features',
        },
        {
            imgSrc: <InfinityIcon iconClr='#0FE3E3' />,
            title: 'Custom Smart Contract Development',
        },
        {
            imgSrc: <MagicIcon iconClr='#0FE3E3' />,
            title: 'Dedicated NFT Web Experts',
        },
        {
            imgSrc: <ChartIcon iconClr='#0FE3E3' />,
            title: 'Regular NFT Market Insights',
        },
        {
            imgSrc: <PauseIcon iconClr='#0FE3E3' />,
            title: 'Scalable NFT Platform Solutions',
        },
        {
            imgSrc: <UpgradeIcon iconClr='#0FE3E3' />,
            title: 'On-demand Service Adjustments',
        },
    ],
};

const PricingBanner = () => {
    // Create separate states for each tab
    const [activeTab, setActiveTab] = useState(tabsData[0].id);

    const [activeItemsTab1, setActiveItemsTab1] = useState(1);
    const [priceTxtTab1, setPriceTxtTab1] = useState(tabsData[0].stepArr[0].price);
    const [priceBtnTab1, setPriceBtnTab1] = useState(tabsData[0].stepArr[0].btnTxt);

    const [activeItemsTab2, setActiveItemsTab2] = useState(1);
    const [priceTxtTab2, setPriceTxtTab2] = useState(tabsData[1].stepArr[0].price);
    const [priceBtnTab2, setPriceBtnTab2] = useState(tabsData[1].stepArr[0].btnTxt);
    const [serviceTitle, setServiceTitle] = useState(tabsData[1].stepArr[0].serviceT);

    const numStepsTab1 = tabsData[0].stepArr.length - 1;
    const stepWidthTab1 = 100 / numStepsTab1;

    const numStepsTab2 = tabsData[1].stepArr.length - 1;
    const stepWidthTab2 = 100 / numStepsTab2;

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handleItemClick = (stepData) => {
        if (window.innerWidth < 600) {
            window.scrollTo(0, 100);
        }
        else if (window.innerWidth <= 1500) {
            window.scrollTo(0, 100);
        }
        else {
            window.scrollTo(0, 0);
        }

        // Use the correct state variables based on the active tab
        if (activeTab === "pills1") {
            setActiveItemsTab1(stepData.id);
            setPriceTxtTab1(stepData.price);
            setPriceBtnTab1(stepData.btnTxt);
        } else if (activeTab === "pills2") {
            setActiveItemsTab2(stepData.id);
            setPriceTxtTab2(stepData.price);
            setPriceBtnTab2(stepData.btnTxt);
            setServiceTitle(stepData.serviceT);
        }
    };

    const lineSecWidthTab1 = `${(activeItemsTab1 > 1 ? activeItemsTab1 - 1 : 0) * stepWidthTab1}%`;
    const lineSecWidthTab2 = `${(activeItemsTab2 > 1 ? activeItemsTab2 - 1 : 0) * stepWidthTab2}%`;

    // Function to retrieve features for a given step
    const getFeaturesForStep = (tabId, stepIndex) => {
        const tab = tabsData.find(tab => tab.id === tabId);
        const step = tab?.stepArr.find(step => step.id === stepIndex);
        const featureKey = step?.featureArr;

        const features = featureArrMap[featureKey];
        if (!features) {
            console.error(`No features found for key: ${featureKey}`);
            return []; // Return an empty array if the key is not found
        }
        return features;
    };

   
    return (
        <section className='py100 bg-[#006363]'>
            <div className="container">
                <div>
                    <div className="flex justify-center gap-7 max-[1370px]:gap-4 max-[575px]:flex-wrap max-[575px]:justify-center max-[575px]:items-center">
                        {tabsData.map((tab) => (
                            <div className="animate__animated animate__fadeInDown" key={tab.id}>
                                <button
                                    className={` fs18 rounded-[10px] border-[1px] p-[14px] border-white font-medium max-[1370px]:p-[10px] hover:bg-[#CAFFFF] hover:text-black ${activeTab === tab.id ? "bg-[#CAFFFF] text-black" : "bg-inherit text-white"}`}
                                    id={`${tab.id}Tab`}
                                    onClick={() => handleTabClick(tab.id)}
                                    type="button"
                                    role="tab"
                                    aria-controls={tab.id}
                                    aria-selected={activeTab === tab.id}
                                >
                                    {tab.label}
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className='text-center mt-10 max-[1370px]:mt-7'>
                        <h1 className=' text-white mb-5 max-[1370px]:mb-2 fs40 font-semibold animate__animated animate__fadeInUp'>
                        Predictable Pricing. No Surprises.</h1>
                        <p className=' text-[#0FE3E3] fs18 font-semibold text-center xl:w-[80%] w-full leading-snug mx-auto animate__animated animate__fadeInUp'>
                        At Pixel Pace Technologies, we believe in transparency and predictability in pricing. Our structured plans are designed to accommodate the unique needs of fast-moving agencies, marketing teams, and scale-ups. Choose from a variety of options to fit the number of active talents working on your projects simultaneously, ensuring you only pay for what you need.
                        </p>
                    </div>
                    <div>
                        {tabsData.map((tab) => (
                            <div
                                  className={`max-[991px]:px-[10px] tabPane transition-opacity duration-300 animate__animated animate__fadeInDown ${activeTab === tab.id ? "opacity-100" : "opacity-0 hidden"
                                    }`}
                                id={tab.id}
                                role="tabpanel"
                                aria-labelledby={`${tab.id}Tab`}
                                tabIndex="0"
                                key={tab.id}
                            >
                                <div className="tabs_scaler flex justify-between items-center mb-40">
                                    {tab.id === 'pills1' ? <div className="price_arrow_wrapper">
                                        <PricingArrow className='price_arrow' iconClr='#0FE3E3' />
                                        <p className="price_arrow_text">Scale up <br /> and down</p>
                                    </div> : ''}
                                    {
                                        tab.stepArr?.map((stepData) => (
                                            <div
                                                key={stepData.id}
                                                className={`scaler_item_wrapper ${activeTab === "pills1" ? activeItemsTab1 >= stepData.id ? 'active' : '' : activeItemsTab2 >= stepData.id ? 'active' : ''} ${activeTab === "pills1" ? activeItemsTab1 === stepData.id ? 'current' : '' : activeItemsTab2 === stepData.id ? 'current' : ''
                                                    }`}
                                                onClick={() => handleItemClick(stepData)}
                                            >
                                                <div className="pulse pulse1"></div>
                                                <div className="pulse pulse2"></div>
                                                <div className="circle"></div>
                                                <div className="circle-text">{stepData.stepIndex}</div>
                                                <a className="circle_link d-inline-block"></a>
                                            </div>
                                        ))
                                    }
                                    <div className="line"></div>
                                    <div className="line line_" style={{ width: activeTab === "pills1" ? lineSecWidthTab1 : lineSecWidthTab2 }}></div>
                                </div>
                                <div className='mt-[80px] md:block text-white flex flex-col'>
                                    <h2 className=' text-[#0FE3E3] max-[575px]:order-2 fs36 font-semibold animate__animated animate__fadeInUp'>
                                        {activeTab === "pills1" ? 'All Inclusive' : serviceTitle}
                                    </h2>
                                    <p className='animate__animated animate__fadeInUp fs22 pb-[40px] pt-4 font-medium max-w-[725px] w-full leading-snug max-[1370px]:pt-2 max-[1370px]:pb-[25px] max-[575px]:order-3 max-[575px]:pb-0'>For dynamic agencies, marketing groups, and growing enterprises seeking reliable, on-demand web design and web development expertise to accelerate their operations even further.</p>
                                    <div className='flex justify-between items-center max-[991px]:flex-wrap max-[575px]:order-1 max-[575px]:flex-col-reverse max-[575px]:mb-4 max-[575px]:gap-4'>
                                        <ul className='flex flex-col flex-wrap h-[168px] gap-4 max-w-[76%] w-full max-[991px]:max-w-[100%] max-[575px]:h-[100%] animate__animated animate__fadeInUp'>
                                            {getFeaturesForStep(tab.id, activeTab === "pills1" ? activeItemsTab1 : activeItemsTab2).map((featureData, index) => (
                                                <li  key={index} className='  flex items-center gap-4 max-[1370px]:gap-2'>
                                                    <div className='w-[20px]'>
                                                        {featureData.imgSrc}
                                                    </div>
                                                    <p className='fs20 font-medium'>{featureData.title}</p>
                                                </li>
                                            ))}

                                        </ul>
                                        <div className='max-w-[24%] w-full text-center max-[991px]:max-w-[100%] relative'>
                                            <div className='max-[991px]:mt-8 max-[991px]:relative max-[991px]:mb-0 max-[991px]:h-[72px] max-[575px]:left-[-15%]'>
                                                <p className='text-start xxl:text-lg text-base absolute left-[-40px] top-[-35px] max-[991px]:left-[35%] max-[991px]:-translate-x-1/2 max-[991px]:top-0'>Starting from</p>
                                                <PricingArrow className='w-[60px] rotate-[25deg] max-[991px]:mx-auto max-[991px]:absolute max-[991px]:left-[35%] max-[991px]:-translate-x-1/2 max-[991px]:bottom-0' iconClr='#0FE3E3' />
                                            </div>
                                            <h3  className='font-semibold   fs40'>{activeTab === "pills1" ? priceTxtTab1 : priceTxtTab2}</h3>
                                            <Button shadowClr="rgba(0,0,0,0.5)" btnLink='https://cal.com/pixelpacetechnology/schedule-your-discovery-call-today' target='_blank' rel="noreferrer" className='pricingBtn flex-row-reverse bg-[#CAFFFF] w-fit py-[14px] px-[27px] gap-[8px] mx-auto my-4 text-[#000] font-semibold fs18 max-[1370px]:py-[12px] max-[1370px]:px-[20px] max-[1370px]:my-2' btnTxt={activeTab === "pills1" ? priceBtnTab1 : priceBtnTab2} btnIcon={<PortfolioArrow iconClr='#000' className='w-[16px] max-[1370px]:w-[14px]' />} />
                                            <span  className='block text-[14px]'>100% Satisfaction Guarantee</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default PricingBanner
