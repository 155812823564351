import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'

const BrowserTabTitle = ({tabTitle,metaDescription}) => {
    const [currentPageURL, setCurrentPageURL] = useState('');
    useEffect(() => {
        setCurrentPageURL(window.location.href);
    }, []);
  return (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{tabTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel='canonical' href={currentPageURL} />
    </Helmet>
  )
}

export default BrowserTabTitle