import React from 'react'
import ServicePage from './ServicePage'
import bgImg from '../../assets/images/ShopifyBg.jpg'
import BrowserTabTitle from '../../components/browserTabTitle'
import { useInView } from 'react-intersection-observer';

const dataArr = [
  {
    subtitle: "Custom Shopify Store Development:",
    title: "We build customized Shopify stores that not only meet your business needs but also stand out in the competitive digital marketplace.",
  },
  {
    subtitle: "Shopify Optimization:",
    title: "Enhance your store’s performance with our optimization services, including SEO, speed optimization, and mobile responsiveness to ensure the best user experience.",
  },
  {
    subtitle: "Integration Services:",
    title: "Seamlessly integrate your Shopify store with essential third-party applications and services to extend functionality and improve operational efficiency.",
  },
  {
    subtitle: "Continuous Support and Maintenance:",
    title: "Our ongoing support ensures your Shopify store remains up-to-date and functions smoothly, helping you focus on sales and business growth.",
  },
]
const Shopify = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const serviceBgStyle = {
    '--bg-image': `url(${bgImg})`,
  };
  return (
    <section className="serviceBg py100" style={serviceBgStyle}>
      <div className="container">
        <BrowserTabTitle tabTitle={'Custom Shopify E-commerce Solutions | Pixel Pace Technologies'} metaDescription={`Revolutionize your online store with Pixel Pace Technologies. Our Shopify services are tailored to launch and enhance e-commerce sites, ensuring a seamless shopping experience for customers. Contact us for expert Shopify solutions that drive growth and increase sales.`} />
        <ServicePage title="Shopify Solutions for E-commerce Success" subTitle='Transform Your Business with Expert Shopify Services' detail="At Pixel Pace Technologies, we specialize in elevating your e-commerce strategy through comprehensive Shopify solutions. Whether you are starting a new e-commerce venture or enhancing an existing one, our expert services are designed to provide a seamless, rewarding shopping experience for your customers." />
        <div className='pt100' ref={ref}>
          <h3 className={`fs28 mb-4 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>Why Choose Pixel Pace for Your Shopify Needs?</h3>
          {
            dataArr.map((data, index) => (
              <div key={index} className={`mb-3 last-of-type:mb-0 ${inView ? 'animate__animated animate__fadeInUp' : ''}`}>
                <p className='fs22 leading-normal'>
                  <b className='mr-1'>
                    {data.subtitle}
                  </b>
                  {data.title}
                </p>
              </div>
            ))
          }
        </div>
      </div>

    </section>
  )
}

export default Shopify
