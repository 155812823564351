import React from 'react'

const UpgradeIcon = ({iconClr, className}) => {
  return (
    <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.68359 22.5741C3.39569 20.6721 1.18359 17.1172 1.18359 13.0457C1.18359 6.97052 6.10846 2.04565 12.1836 2.04565C18.2587 2.04565 23.1836 6.97052 23.1836 13.0457C23.1836 17.1172 20.9715 20.6721 17.6836 22.5741M16.5836 13.0457L12.1836 8.64573M12.1836 8.64573L7.78359 13.0457M12.1836 8.64573V24.0457" stroke={iconClr} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default UpgradeIcon
