import React from 'react'

const InfinityIcon = ({iconClr, className}) => {
    return (
        <svg className={`${className}`} width="100%" height="100%" viewBox="0 0 27 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9717 1.24707C27.085 1.24707 27.085 10.8441 20.9717 10.8441C14.8596 10.8441 12.4148 1.24707 5.68967 1.24707C0.18938 1.24707 0.18938 10.8441 5.68967 10.8441C12.4148 10.8441 14.8596 1.24707 20.9729 1.24707H20.9717Z" stroke={iconClr} strokeWidth="2.2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default InfinityIcon
