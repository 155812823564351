import React from 'react'

const BlueCheck = ({className,iconClr}) => {
  return (
<svg className={`${className}`} width="100%" height="100%" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.0546 2.13184L6.80895 13.3775L1.69727 8.26585" stroke={iconClr} strokeWidth="2.3" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default BlueCheck
